import { SET_CLIENT } from '../actions/client.actions';
import { SET_CLIENTS } from '../actions/clients.actions';

const defaultState = {
    clients: null,
    client: null
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_CLIENTS:
            return {
                ...state,
                clients: action.clients
            }
        case SET_CLIENT:
            return {
                ...state,
                client: action.client
            }
        default:
            return state;

    }
}