import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import Select from '../../../application/components/formComponents/Select';
import ZamknijWhite from '../../../assets/icons/zamknij-white.svg';

import './selectedProduct.css'

const SelectedProduct = ({ prod, prod: { priceCurrency, discount }, i, handleChangeProductPriceCurrency, handleChangeProductDiscount, handleDeleteSelectedProduct }) => {

    const [selectedDiscount, setSelectedDiscount] = useState();
    const [price, setPrice] = useState(0);
    const [disc, setDisc] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState('PLN');
    useEffect(() => {
        if (priceCurrency) {
            const currency = Object.keys(priceCurrency);
            if (currency) {
                setSelectedDiscount('price')
                setSelectedCurrency(currency[0])
                setPrice(priceCurrency[currency[0]])
            }
        } else {
            setPrice(0);
        }
    }, [priceCurrency])
    useEffect(() => {
        if (discount) {
            setDisc(discount)
            setSelectedDiscount('discount')
        } else {
            setDisc(0)
        }

    }, [discount])

    const handleChangeSelectedDiscount = (value) => {
        //resetujemy drugą wartość
        if (value === 'price') {
            handleChangeProductDiscount(i, null);
            setDisc(0)
        } else {
            handleChangeProductPriceCurrency(i, null, selectedCurrency);
            setPrice(0);
        }

        setSelectedDiscount(value)
    }

    const changePrice = (val) => {
        setPrice(val);
        handleChangeProductPriceCurrency(i, val, selectedCurrency)
    }

    const changeCurrency = (val) => {
        setSelectedCurrency(_.get(val, 'name'));
        handleChangeProductPriceCurrency(i, price, _.get(val, 'name'))
    }

    return (
        <tr>
            <td>{i + 1}. {_.get(prod, 'name.pl')}</td>
            <td width="150px">
                    <Select
                        placeholder={'Waluta'}
                        options={[{ name: 'PLN' }, { name: 'EUR' }]}
                        value={selectedCurrency}
                        onChange={(val) => changeCurrency(val)}
                        noFormik={true}
                    />
            </td>
            <td width="150px">
                <div className="selected-product-inputs">
                    <input type="radio" id="price" name={`pricing${_.get(prod, 'name.pl')}`} value="price"
                        onChange={(e) => handleChangeSelectedDiscount(e.target.value)}
                        checked={(selectedDiscount === 'price') ? true : false}
                    />
                    <input type="number" name="pricing__price" className="border--light"
                        disabled={(selectedDiscount === 'price') ? false : true}
                        onChange={(e) => changePrice(e.target.value)}
                        value={price}
                    ></input>
                </div>

            </td>
            <td width="150px"
            >
                <div className="selected-product-inputs">
                    <input type="radio" id="discount" name={`pricing${_.get(prod, 'name.pl')}`} value="discount"
                        onChange={(e) => handleChangeSelectedDiscount(e.target.value)}
                        checked={(selectedDiscount === 'discount') ? true : false}
                    />
                    <input type="number" name="pricing__discount" className="border--light"
                        disabled={(selectedDiscount === 'discount') ? false : true}
                        onChange={(e) => handleChangeProductDiscount(i, e.target.value)}
                        value={disc}
                    ></input>
                </div>

            </td>

            <td width="150px">
                <div className="client__action client__action--delete"  onClick={() => handleDeleteSelectedProduct(i)} >
                    <img src={ZamknijWhite} alt=""/>
                </div>
            </td>
        </tr>
    )
}

export default SelectedProduct;