import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './orders.css';

import Select from '../../application/components/formComponents/Select';
import Search from '../../application/components/search/Search';
import Orders from '../components/Orders.component';
import Pagination from '../../application/components/pagination/Pagination.component';

import { getOrders, setOrdersQuery, setOrdersFilter, clearOrders, ordersNextPage, ordersPrevPage, goToOrdersPage } from '../actions/orders.actions'


const OrdersContainer = ({ getOrders, orders, setOrdersQuery, setOrdersFilter, clearOrders, params, total, ordersNextPage, ordersPrevPage, goToOrdersPage }) => {
    const { t } = useTranslation();

    useEffect(() => {
        getOrders()
    }, [getOrders])

    useEffect(() => {
        return () => {
            clearOrders();
        }
    }, [clearOrders])

    const [filter, setFilter] = useState('')

    const handleOrdersSearch = (query) => {
        setOrdersQuery(query)
    }

    const handleFilterChange = (filter) => {
        setFilter(filter);
        setOrdersFilter(filter)
    }

    return (
        <section id="orders" className="orders content">
            <div className="container">
                <div className="row mt-5">
                    <div className="col-12 mt-5">
                        <h1>{t('orders')}</h1>
                    </div>


                    <div className="col-12 mt-5">
                        <Search
                            onChange={(query) => handleOrdersSearch(query)}
                        />
                    </div>


                    <div className="col-lg-5 mt-5">

                        <Select
                            id="filters"
                            label={t('filters')}
                            placeholder={t('filters')}
                            options={[
                                { name: t('allFilters'), value: null },
                                { name: t('completed'), value: 'completed' },
                                { name: t('rejected'), value: 'rejected' },
                                { name: t('newOrder'), value: 'new' }
                            ]}
                            noFormik={true}
                            value={filter.name}
                            onChange={(filter) => handleFilterChange(filter)}
                        />
                    </div>

                    {orders && orders.length > 0 &&
                        <div className="col-lg-12 mt-5">
                            <Pagination
                                onPrevPageClick={ordersPrevPage}
                                onNextPageClick={ordersNextPage}
                                onPageClick={goToOrdersPage}
                                params={params}
                                total={total}
                            />
                        </div>
                    }

                    {
                        (orders && orders.length > 0) ?
                            <Orders
                                orders={orders}
                            />
                            : <div className="col-lg-12 mt-5">{t('noOrders')}</div>

                    }


                    {orders && orders.length > 0 &&
                        <div className="col-lg-12 mt-5">
                            <Pagination
                                onPrevPageClick={ordersPrevPage}
                                onNextPageClick={ordersNextPage}
                                onPageClick={goToOrdersPage}
                                params={params}
                                total={total}
                            />
                        </div>
                    }


                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (store) => ({
    orders: store.orders.orders,
    params: store.orders.params,
    total: store.orders.ordersTotal,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getOrders: () => dispatch(getOrders()),
        setOrdersQuery: (querty) => dispatch(setOrdersQuery(querty)),
        setOrdersFilter: (filter) => dispatch(setOrdersFilter(filter)),
        clearOrders: () => dispatch(clearOrders()),
        ordersNextPage: () => dispatch(ordersNextPage()),
        ordersPrevPage: () => dispatch(ordersPrevPage()),
        goToOrdersPage: (page) => dispatch(goToOrdersPage(page)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersContainer);