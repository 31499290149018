import React from 'react';
import { connect } from 'react-redux'

import AdminSeller from '../../components/sellers/AdminSeller.component';
import AdminSellers from '../../components/sellers/AdminSellers.component';

import { getClients } from '../../actions/clients/adminClients.actions';
import { getSellers } from '../../actions/sellers/adminSellers.actions';
// import { createClientAccount, getExternalClients, getClient, changeIsActiveClient } from '../../actions/clients/adminClient.actions';
import { changeIsActiveSeller, getSeller, getExternalSellers, createSellerAccount, deleteSellerAccount, clearSeller, editSellerAccount } from '../../actions/sellers/adminSeller.actions';

const AdminSellerModule = (props) => {

    return (
        <React.Fragment>
            <AdminSeller
                createSellerAccount={props.createSellerAccount}
                externalSellers={props.externalSellers}
                getClients={props.getClients}
                getExternalSellers={props.getExternalSellers}
                seller={props.seller}
                clients={props.clients}
                history={props.history}
                deleteSellerAccount={props.deleteSellerAccount}
                getSeller={props.getSeller}
                clearSeller={props.clearSeller}
                editSellerAccount={props.editSellerAccount}
            />

            <AdminSellers
                getSellers={props.getSellers}
                sellers={props.sellers}
                changeIsActiveSeller={props.changeIsActiveSeller}
                deleteSellerAccount={props.deleteSellerAccount}
            />
        </React.Fragment>
    )
}

const mapStateToProps = (store) => ({
    externalSellers: store.admin.externalSellers,
    sellers: store.admin.sellers,
    seller: store.admin.seller,
    clients: store.admin.clients,
    history: store.app.history
});

const mapDispatchToProps = (dispatch) => {
    return {
        getExternalSellers: (query) => dispatch(getExternalSellers(query)),
        getSellers: (query) => dispatch(getSellers(query)),
        getClients: (query) => dispatch(getClients(query)),
        createSellerAccount: (user) => dispatch(createSellerAccount(user)),
        getSeller: (id) => dispatch(getSeller(id)),
        changeIsActiveSeller: (value, id) => dispatch(changeIsActiveSeller(value, id)),
        deleteSellerAccount: (id) => dispatch(deleteSellerAccount(id)),
        editSellerAccount: (id, values) => dispatch(editSellerAccount(id, values)),
        clearSeller: () => dispatch(clearSeller()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSellerModule);