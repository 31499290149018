import Api from '../../../services/api';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { isLoading, displayErrors } from '../../../application/actions/application.actions';
import { getClients } from './adminClients.actions';

const NAMESPACE = 'ADMIN';

export const SET_PRODUCT_CATEGORY_LIST = `SET_PRODUCT_CATEGORY_LIST_${NAMESPACE}`;
export const SET_EXTERNAL_CLIENTS = `SET_EXTERNAL_CLIENTS_${NAMESPACE}`;
export const SET_CLIENT = `SET_CLIENT_${NAMESPACE}`;
export const CLEAR_CLIENT = `CLEAR_CLIENT_${NAMESPACE}`;

export const createClientAccount = (user) => {
    return async (dispatch) => {
        dispatch(isLoading(true));

        user = setUserObjectForCreation(user);

        return Api.post('admin/user', user).then(res => {
            dispatch(isLoading(false));

            if (res.success) {
                // toast.info('Klient utworzony');
                dispatch(getClients());
                return Promise.resolve({ success: true })
            } else {
                if (res.errors) {
                    displayErrors(res.errors);
                    return Promise.resolve({ success: false, errors: res.errors })
                }
            }
        }).catch(err => {
            displayErrors(err);
            dispatch(isLoading(false));
        })
    }
}

export const editClientAccount = (id, user) => {
    return async (dispatch) => {
        dispatch(isLoading(true));

        user = setUserObjectForCreation(user);

        return Api.put(`admin/user/${id}`, user).then(res => {
            dispatch(isLoading(false));
            if (res.success) {
                // toast.info('Klient zaktuaizowany');
                dispatch(getClients());
                return Promise.resolve({ success: true })
            } else {
                if (res.errors) {
                    displayErrors(res.errors);
                    return Promise.resolve({ success: false, errors: res.errors })
                }
            }
        }).catch(err => {
            displayErrors(err);
            dispatch(isLoading(false));
        })
    }
}

const setUserObjectForCreation = (user) => {
    user.type = "company";
    user.language = "pl";
    user.foreignId = _.get(user, "client.foreignId");
    user.name = _.get(user, "client.name");
    user.currency = _.get(user, "currency.name");
    return user;
}

const extractIdsFromSellers = (clients) => {
    let ids = [];
    clients.forEach(c => {
        if (c._id) ids.push(c._id);
    })

    return ids;
}



export const deleteClientAccount = (id) => dispatch => {
    dispatch(isLoading(true));

    return Api.delete(`admin/user/${id}`).then(res => {
        dispatch(isLoading(false));
        if (res.success) {
            toast.info('Konto usunięte');
            dispatch(getClients());
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })

}


export const getExternalClients = (searchQuery) => (dispatch, getState) => {
    const params = { ...getState().admin.params };

    if (searchQuery && searchQuery !== "" && params) {
        params[`name[orin]`] = searchQuery;
    }

    dispatch(isLoading(true));

    Api.get('company/company', params).then(res => {
        if (res.success) {
            dispatch(setExternalClients(res.documents));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

const setExternalClients = (externalClients) => dispatch => {
    dispatch({
        type: SET_EXTERNAL_CLIENTS,
        externalClients
    })
}

export const getClient = (id) => dispatch => {
    dispatch(isLoading(true));

    Api.get(`admin/user/${id}`).then(res => {
        if (res.success) {
            dispatch(setClient(res.document));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

const setClient = (client) => dispatch => {
    dispatch({
        type: SET_CLIENT,
        client
    })
}

export const changeIsActiveClient = (value, id) => dispatch => {
    dispatch(isLoading(true));

    Api.put(`admin/changeIsActive/${id}`, { isActive: value }).then(res => {
        if (res.success) {
            toast.info('Klient aktywowany');
            dispatch(getClients());
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const clearClient = () => dispatch => {
    dispatch({
        type: CLEAR_CLIENT
    })
}

export const getProductCategoryList = () => (dispatch, getState) => {
    dispatch(isLoading(true));

    Api.get('admin/productCategoryList').then(res => {
        if (res.success) {
            dispatch(setProductcategoryList(res.documents));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

const setProductcategoryList = (productCategoryList) => dispatch => {
    dispatch({
        type: SET_PRODUCT_CATEGORY_LIST,
        productCategoryList
    })
}

