import { logout } from '../application/actions/application.actions';
import _ from "lodash";

class Api {

  constructor() {
    this.host = 'https://zoo-api.over-b2b.com/';
    // this.host = 'https://api-b2b.overcloud.usermd.net/';
    // this.host = 'http://localhost:3001/';
    this.headers = {
      'Content-Type': 'application/json'
    }
  }

  getHost() {
    return this.host;
  }

  getToken() {
    return localStorage.getItem('token') ? `JWT:${localStorage.getItem('token')}` : false;
  }

  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  getDispatch() {
    return this.dispatch;
  }


  async get(url, params, isFormData) {
    if (params) {
      url += "?" + Object.keys(params).map(function (key) {
        return key + '=' + params[key];
      }).join('&');
    }

    return await this.request(this.host + url, 'GET', null, isFormData);
  }

  async post(url, data, isFormData) {
    return await this.request(this.host + url, 'POST', data, isFormData);
  }

  async put(url, data, id, isFormData) {
    if (id) {
      url += `/${id}`;
    }
    return await this.request(this.host + url, 'PUT', data, isFormData);
  }

  async delete(url, id) {
    if (id) {
      url += `/${id}`;
    }
    return await this.request(this.host + url, 'DELETE', null);
  }

  setFormData(data) {
    let form = new FormData();
    _.each(data, (value, key) => {
      if (value instanceof File) {
        form.append(key, value, value.name);
      }
      if (!_.isEmpty(value)) {
        if (_.isObject(value)) {
          _.each((value), (v, k) => {
            form.append(`${key}[${k}]`, v);
          });
        }
        else if (_.isArray(value)) {
          _.each((value), (v) => {
            if (v instanceof File) {
              form.append(`${key}[]`, v, v.name);
            } else {
              form.append(`${key}[]`, v);
            }
          });
        } else {
          form.append(key, value)
        }
      }
    });
    return form;
  }

  async request(url, method, data, isFormData = false) {
    if (this.getToken()) {
      this.headers.authorization = this.getToken();
    }

    let options = {
      method: method,
      headers: this.headers
    }

    if (isFormData) {
      options.headers = { authorization: this.getToken() };
    }

    if (data && Object.keys(data).length > 0) {
      if (isFormData) {
        delete options.headers['Content-Type'];
        options.body = this.setFormData(data);
      } else {
        options.body = JSON.stringify(data);
      }
    }

    return await fetch(url, options).then((response) => {
      switch (response.status) {
        case 200:
        case 201:
        case 422:
        case 404:
        case 403:
          return response.json();
        case 401:
          // bardzo ważny element, usuwamy token i zalogowanego uzytkownika ze stora jak jakiś request zwróci 401
          if (localStorage.getItem('token')) {
            this.getDispatch()(logout());
          }
          return response.json();
        default:
          return response.json();
      }

    }).then((data) => {
      return data;
    });

  }
}

export default new Api();