import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import './clientDashboard.css'

import LoyaltyPoints from '../../components/clientDashboard/LoyaltyPoints.component';

const ClientDashboardContainer = ({ user }) => {
    const { t } = useTranslation();

    const LoyaltyPrograms = () => {
        const loyaltyPrograms = _.get(user, 'loyaltyPrograms');

        if (!loyaltyPrograms) return;

        return loyaltyPrograms.map((p, i) => {
            return (
                <LoyaltyPoints
                    key={i}
                    points={p.points}
                    value={p.plnValue}
                    name={p.name}
                />
            )
        })
    }


    return (
        <section id="dashboard" class="dashboard content">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>{t('loyaltyPoints')}</h1>
                        <div className="row mt-4">
                            {LoyaltyPrograms()}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => ({
    user: state.app.user
});

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDashboardContainer);