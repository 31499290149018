import { Formik } from 'formik';
import _ from "lodash";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import Select from '../../../application/components/formComponents/Select';
import TextInput from '../../../application/components/formComponents/TextInput';
import ProductPicker from '../../components/pricing/ProductPicker.component';
import { clearProducts } from '../../../products/actions/products.actions';
import { clearDiscountGroup, createDiscountGroup, editDiscountGroup, getDiscountGroup, setDiscountGroupErrors, getProductsDiscountGroup } from '../../actions/discountGroup/discountGroup.actions';
import ZamknijWhite from '../../../assets/icons/zamknij-white.svg';
import SelectedProducts from '../../components/discountGroup/SelectedProducts.component';
import './discountGroup.css';
import { useParams } from 'react-router-dom';

const DiscountGroupContainer = ({ products, createDiscountGroup, getDiscountGroup, discountGroup, editDiscountGroup, clearDiscountGroup, getProductsDiscountGroup }) => {

    const { t } = useTranslation();

    const params = useParams();
    useEffect(() => {
        if (params && params.id) {
            getDiscountGroup(params.id);
        }
        return () => {
            clearDiscountGroup();
        }  
    }, [getDiscountGroup]);

    const handlePricingSubmit = (values) => {
        if (values.currency && values.currency.name) {
            values.currency = values.currency.name;
        }
        if (params && params.id) {
            editDiscountGroup(params.id, values);
        } else {
            createDiscountGroup(values);
        }
    }

    const addThreshold = (formik) => {
        let maxValue = 0;
        _.each(formik.values.thresholds, (threshold) => {
            if (threshold.priceTo > maxValue) {
                maxValue = threshold.priceTo;
            }
        });
        maxValue = parseFloat(maxValue);
        let newValues = _.concat(formik.values.thresholds, { priceFrom: maxValue > 0 ? maxValue + 1 : 0, priceTo: maxValue + 100, discount: 0 });
        formik.setFieldValue("thresholds", newValues);
    }

    const deleteThreshold = (index, formik) => {
        formik.setFieldValue("thresholds", _.without(formik.values.thresholds, _.nth(formik.values.thresholds, index)));
    }

    const handleProductAdd = (product, formik) => {
        let exists = _.find(formik.values.products, (p) => p.foreignId === product.foreignId);
        if (!exists) {
            let newValues = _.concat(formik.values.products, _.pick(product, ["name", "foreignId"]));
            formik.setFieldValue("products", newValues);
        }
    }

    const handleProductDelete = (index, formik) => {
        formik.setFieldValue("products", _.without(formik.values.products, _.nth(formik.values.products, index)));
    }

    const renderErrors = (errors) => {
        let messages = [];
        if (_.isArray(errors) || _.isObject(errors)) {
            _.each(errors, (value, key) => {
                messages = _.concat(messages, renderErrors(value));
            });
        } else if (_.isString(errors)) {
            messages.push(<div class="text-danger" key={`error`}>{errors}</div>);
        }
        return messages;
    }

    return (
        <section id="discountGroup" className="discountGroup content" style={{ width: '100%' }} >
            <div className="container">
                <div className="row">
                    <div className="col">

                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                name: (discountGroup && discountGroup.name) ? discountGroup.name : '',
                                currency: (discountGroup && discountGroup.currency) ? { name: discountGroup.currency } : '',
                                thresholds: (discountGroup && discountGroup.thresholds) ? discountGroup.thresholds : [],
                                products: (discountGroup && discountGroup.products) ? discountGroup.products : [],
                            }}
                            validationSchema={Yup.object({
                                name: Yup.string().required(`Nazwa jest wymagana`),
                                currency: Yup.string().required(t('currencyRequired')),
                                thresholds: Yup.array().of(Yup.object().shape({
                                    priceFrom: Yup.number().required("Wartość jest wymagana"),
                                    priceTo: Yup.number().required("Wartość jest wymagana"),
                                    discount: Yup.number().required("Wartość jest wymagana")
                                })).required('Nie dodano żadnego progu rabatowego'),
                                products: Yup.array().of(Yup.object().shape({
                                    name: Yup.string().required("Nazwa jest wymagana"),
                                    foreignId: Yup.string().required("Wartość jest wymagana")
                                })).required('Nie dodano żadnego produktu')
                            })}
                            validateOnChange={false}
                            validateOnBlur={false}
                            enableReinitialize={true}
                            onSubmit={(values) => {
                                handlePricingSubmit(values);
                            }}
                        >
                            {formik => (
                                <React.Fragment>
                                    <div className="row">
                                        <div className=" col-12 table-responsive rounded-box p-5">
                                            <div className='col-md-12'>
                                                <TextInput
                                                    id="name"
                                                    label="Nazwa"
                                                    type="text"
                                                    onChange={formik.setFieldValue}
                                                    value={formik.values.name}
                                                    errors={formik.errors.name}
                                                    extraClass={"p-0"}
                                                />
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <Select
                                                    id="currency"
                                                    label={t('currency')}
                                                    placeholder={t('chooseCurrency')}
                                                    options={[{ name: 'PLN' }, { name: 'EUR' }]}
                                                    value={_.get(formik, 'values.currency.name')}
                                                    onChange={formik.setFieldValue}
                                                    errors={formik.errors.currency}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className=" col-12 table-responsive rounded-box p-5">
                                            <div className="col-md-12 mt-4">
                                                {formik.errors.thresholds && renderErrors(formik.errors.thresholds)}
                                                <label>Progi rabatowe</label>
                                                {_.get(formik.values, "thresholds", []).map((threshold, index) => {
                                                    return <div className='row mb-4' key={`threshold${index}`}>
                                                        <TextInput
                                                            id={`thresholds[${index}].priceFrom`}
                                                            label="Cena od"
                                                            type="number"
                                                            onChange={formik.setFieldValue}
                                                            value={_.get(formik, `values.thresholds[${index}].priceFrom`, 0)}
                                                            errors={_.get(formik, `errors.thresholds[${index}].priceFrom`)}
                                                            size={"small"}
                                                            min={0}
                                                        />
                                                        <TextInput
                                                            id={`thresholds[${index}].priceTo`}
                                                            label="Cena do"
                                                            type="number"
                                                            onChange={formik.setFieldValue}
                                                            value={_.get(formik, `values.thresholds[${index}].priceTo`, 0)}
                                                            errors={_.get(formik, `errors.thresholds[${index}].priceTo`)}
                                                            size={"small"}
                                                            min={0}
                                                        />
                                                        <TextInput
                                                            id={`thresholds[${index}].discount`}
                                                            label="Rabat"
                                                            type="number"
                                                            onChange={formik.setFieldValue}
                                                            value={_.get(formik, `values.thresholds[${index}].discount`, 0)}
                                                            errors={_.get(formik, `errors.thresholds[${index}].discount`)}
                                                            size={"small"}
                                                            min={0}
                                                            max={100}
                                                        />
                                                        <div className='col-1 action-button'>
                                                            {/* <label></label> */}
                                                            <div class="client__action client__action--delete"><img src={ZamknijWhite} alt="" onClick={() => deleteThreshold(index, formik)} /></div>
                                                            {/* <button className='btn btn-danger' onClick={() => deleteThreshold(index, formik)}><img src={ZamknijWhite} alt="" /></button> */}
                                                        </div>
                                                    </div>
                                                })}
                                                <button className="btn btn--primary"
                                                    onClick={() => addThreshold(formik)}
                                                >{t('addAction')}</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className=" col-12 table-responsive rounded-box p-5">
                                            <div className="col-md-12 mt-4">
                                                {formik.errors.products && renderErrors(formik.errors.products)}
                                                <ProductPicker
                                                    getProducts={getProductsDiscountGroup}
                                                    products={products}
                                                    handleProductAdd={(product) => handleProductAdd(product, formik)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-4'>
                                        <SelectedProducts
                                            selectedProducts={formik.values.products}
                                            handleDeleteSelectedProduct={(i) => handleProductDelete(i, formik)}
                                        />
                                    </div>

                                    <div className="row mt-5 justify-content-center">
                                        <div className="col-auto">
                                            <button id="discountGroupSave" className="btn btn--primary"
                                                onClick={formik.handleSubmit}
                                            >{t('saveAction')}</button>
                                        </div>
                                    </div>

                                </React.Fragment>
                            )}
                        </Formik>

                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (store) => ({
    discountGroup: store.admin.discountGroup,
    productCategoriesTree: store.products.productCategoriesTree,
    products: store.products.products,
    discountGroupErrors: store.admin.discountGroupErrors

});

const mapDispatchToProps = (dispatch) => {
    return {
        getProductsDiscountGroup: (id, noLimit) => dispatch(getProductsDiscountGroup(id, noLimit)),
        createDiscountGroup: (data) => dispatch(createDiscountGroup(data)),
        getDiscountGroup: (id) => dispatch(getDiscountGroup(id)),
        editDiscountGroup: (id, data) => dispatch(editDiscountGroup(id, data)),
        clearDiscountGroup: () => dispatch(clearDiscountGroup()),
        clearProducts: () => dispatch(clearProducts()),
        setDiscountGroupErrors: (errors) => dispatch(setDiscountGroupErrors(errors)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountGroupContainer);