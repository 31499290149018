import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import ImageCarousel from '../../application/components/product/ProductImageCarousel.component';
import ProductPrice from '../../application/components/product/ProductPrice.component';
import ProductStock from '../../application/components/product/ProductStock.component';
import AddToCart from '../../application/components/product/AddToCart.component';
import ProductQuantity from '../../application/components/product/ProductQuantity.component';
import SelectVariant from '../../application/components/product/SelectVariant.component';

import './product.css';

const Product = ({ product, variant, handleAdd, handleSubtract, productQuantity, addProductToCart, handleChangeVariant, stockSettings, showPrices, userType }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (product && product.images.length) {
            variant.image = product.images[0];
        }
    }, [product]);


    const renderVariantView = () => {
        if (!_.get(product, 'variants', []).length < 2) {
            return;
        }

        return <>
            <p className="price__label mt-5">{t('variants')}</p>
            <div className="mt-2">
                <SelectVariant
                    placeholder={t('chooseVariant')}
                    options={_.get(product, 'variants')}
                    currency={_.get(product, 'currency')}
                    onChange={(variant) => handleChangeVariant(variant)}
                    showPrices={showPrices}
                />
            </div>
        </>
    }

    return (
        <div className="col">
            <div className="row product__main align-items-center">
                <div className="col-lg-6">

                    <ImageCarousel
                        images={_.get(product, 'images')}
                    />
                </div>
                <div className="col mt-5 mt-lg-0">
                    <h1>{_.get(product, `name[${localStorage.getItem('language')}]`, _.get(product, "name.pl"))}</h1>
                    <h2>{_.get(variant, `name[${localStorage.getItem('language')}]`, _.get(variant, "description.pl"))}</h2>


                    {renderVariantView()}

                    <p className="price__label mt-5">{t('price')}</p>
                    <ProductPrice
                        discountedPrice={_.get(variant, 'discountedPrice')}
                        priceBeforeDiscount={_.get(variant, 'priceBeforeDiscount')}
                        price={_.get(variant, 'price')}
                        currency={_.get(product, 'currency')}
                        showPrices={showPrices}
                    />
                    <div className="mt-5">
                        <ProductStock
                            amount={_.get(variant, 'warehouseAmount')}
                            stockSettings={stockSettings}
                        />
                    </div>
                    <div className="mt-5">
                        {
                            (userType !== 'admin' && _.get(variant, 'price') > 0) &&
                            <ProductQuantity
                                handleAdd={handleAdd}
                                handleSubtract={handleSubtract}
                                value={productQuantity}
                            />
                        }

                    </div>
                    {
                        (userType !== 'admin' && _.get(variant, 'price') > 0) &&
                        <AddToCart
                            addProductToCart={addProductToCart}
                            item={variant}
                            amount={productQuantity}
                        />
                    }
                </div>

            </div>

            <div className="row product__desc mt-50">
                <div className="col-12">
                    <p> {_.get(product, `description[${localStorage.getItem('language')}]`, _.get(product, "description.pl"))}</p>
                </div>
            </div>
        </div>
    )
}

export default Product;