import Api from '../../../services/api';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { isLoading, displayErrors } from '../../../application/actions/application.actions';
import { getSellers } from './adminSellers.actions';
const NAMESPACE = 'ADMIN';

export const SET_EXTERNAL_SELLERS = `SET_EXTERNAL_SELLERS_${NAMESPACE}`;
export const SET_SELLER = `SET_SELLER_${NAMESPACE}`;
export const CLEAR_SELLER = `CLEAR_SELLER_${NAMESPACE}`;

export const createSellerAccount = (user) => {
    return async (dispatch) => {
        dispatch(isLoading(true));

        user = setUserObjectForCreation(user);

        return Api.post('admin/user', user).then(res => {
            dispatch(isLoading(false));

            if (res.success) {
                // toast.info('Handlowiec utworzony');
                dispatch(getSellers());
                return Promise.resolve({ success: true })
            } else {
                if (res.errors) {
                    displayErrors(res.errors);
                    return Promise.resolve({ success: false, errors: res.errors })
                }
            }
        }).catch(err => {
            displayErrors(err);
            dispatch(isLoading(false));
            return Promise.resolve({ success: false, errors: err })
        })
    }
}

export const editSellerAccount = (id, user) => {
    return async (dispatch) => {
        dispatch(isLoading(true));

        user = setUserObjectForCreation(user);

        return Api.put(`admin/user/${id}`, user).then(res => {
            dispatch(isLoading(false));
            if (res.success) {
                // toast.info('Handlowiec zaktuaizowany');
                dispatch(getSellers());
                return Promise.resolve({ success: true })
            } else {
                if (res.errors) {
                    displayErrors(res.errors);
                    return Promise.resolve({ success: false, errors: res.errors })
                }
            }
        }).catch(err => {
            displayErrors(err);
            dispatch(isLoading(false));
        })
    }
}

const setUserObjectForCreation = (user) => {
    let newUser = _.cloneDeep(user);
    newUser.type = "salesman";
    newUser.language = "pl";
    newUser.companies = (newUser.clients) ? extractIdsFromClients(newUser.clients) : null;
    newUser.foreignId = _.get(newUser, "seller.foreignId");
    newUser.name = _.get(newUser, "seller.name");
    delete newUser.clients;
    delete newUser.seller;
    return newUser;
}

const extractIdsFromClients = (clients) => {
    let ids = [];
    clients.forEach(c => {
        if (c._id) ids.push(c._id);
    })

    return ids;
}

export const getExternalSellers = (searchQuery) => (dispatch, getState) => {
    const params = {...getState().admin.params};

    if (searchQuery && searchQuery !== "" && params) {
        params.select.split(",").forEach((field) => {
            params[`${field}[orin]`] = searchQuery;
        });
    }

    dispatch(isLoading(true));

    Api.get('user/user', params).then(res => {
        if (res.success) {
            dispatch(setExternalSellers(res.documents));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

const setExternalSellers = (externalSellers) => dispatch => {
    dispatch({
        type: SET_EXTERNAL_SELLERS,
        externalSellers
    })
}

export const getSeller = (id) => dispatch => {
    dispatch(isLoading(true));

    Api.get(`admin/user/${id}`).then(res => {
        if (res.success) {
            dispatch(setSeller(res.document));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

const setSeller = (seller) => dispatch => {
    dispatch({
        type: SET_SELLER,
        seller
    })
}

export const changeIsActiveSeller = (value, id) => dispatch => {
    dispatch(isLoading(true));

    Api.put(`admin/changeIsActive/${id}`, { isActive: value }).then(res => {
        if (res.success) {
            toast.info('Handlowiec aktywowany');
            dispatch(getSellers());
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const deleteSellerAccount = (id) => dispatch => {
    dispatch(isLoading(true));

    return Api.delete(`admin/user/${id}`).then(res => {
        dispatch(isLoading(false));
        if (res.success) {
            toast.info('Konto usunięte');
            dispatch(getSellers());
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const clearSeller = () => dispatch =>{
    dispatch({
        type: CLEAR_SELLER
    })
}