import Api from '../../../services/api';
import { isLoading, displayErrors } from '../../../application/actions/application.actions';

const NAMESPACE = 'ADMIN';

export const SET_CLIENTS = `SET_CLIENTS_${NAMESPACE}`;

export const getClients = (searchQuery = null) => (dispatch, getState) => {
    const params = { ...getState().admin.params };
    params.type = "company"

    if (searchQuery && searchQuery !== "" && params) {
        params[`name[orin]`] = searchQuery;
    }

    dispatch(isLoading(true));

    Api.get('admin/user', params).then(res => {
        if (res.success) {
            dispatch(setClients(res.documents));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

const setClients = (clients) => dispatch => {
    dispatch({
        type: SET_CLIENTS,
        clients
    })
}