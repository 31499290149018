import React from 'react';

const Select = ({ id, label, placeholder, options, value, onChange, errors, noFormik }) => {
    const renderOptions = () => {
        if (options) {
            return options.map((o, i) => {
                return (
                    <div className="dropdown-item" key={i}
                        onClick={(noFormik) ? () => onChange(o) : () => onChange(id, o)}
                    >{o.name}</div>
                )
            })
        }
    }

    return (
        <div className={`select d-block d-xl-block ${errors ? 'input--error' : ''}`}>
            {label &&
                <label>{label}</label>
            }
            <div className="dropdown dropdown--select ">
                <div className={`dropdown-toggle ${errors ? 'border--error' : 'border--light'}`} type="button" id="dropdownSelect" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    {(value) ? value : placeholder}
                </div>
                <div className="dropdown-menu" aria-labelledby="dropdownSelect" >
                    {renderOptions()}
                </div>
            </div>
            {errors && <div className="error__message">{errors}</div>}
        </div>
    )
}

export default Select;