import { toast } from 'react-toastify';
import Api from '../../services/api';
import { clearUser, saveUser } from '../actions/login.action';

export const NAMESPACE = "APPLICATION";
export const IS_LOADING = `IS_LOADING_${NAMESPACE}`;
export const DECREASE_LOADING_ITEMS = `DECREASE_LOADING_ITEMS_${NAMESPACE}`;
export const INCREASE_LOADING_ITEMS = `INCREASE_LOADING_ITEMS_${NAMESPACE}`;
export const SAVE_HISTORY = `SAVE_HISTORY_${NAMESPACE}`;
export const SAVE_SETTINGS = `SAVE_SETTINGS_${NAMESPACE}`;

export const isAuth = () => (dispatch, getState) => {
    const history = getState().app.history;

    dispatch(isLoading(true));
    Api.get('application/isAuth').then(res => {
        if (res.success) {
            dispatch(saveUser(res.user));
            dispatch(getApplicationSettings());
            // dispatch(navigateToPageAfterLogin(_.get(res, 'user.type')));
        } else {
            if (history.location.pathname !== '/login' && !history.location.pathname.includes('changePassword')) {
                history.push('/login')
            }
            // window.location.reload();
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        console.error(err);
    })
}

export const isLoading = (isLoading) => (dispatch, getState) => {
    const loadingItems = getState().app.loadingItems;

    if (isLoading && loadingItems === 0) {
        dispatch({
            type: IS_LOADING,
            isLoading
        })
        dispatch({
            type: INCREASE_LOADING_ITEMS
        })
    } else if (isLoading && loadingItems > 0) {
        dispatch({
            type: INCREASE_LOADING_ITEMS
        })
    } else if (!isLoading && loadingItems === 1) {
        dispatch({
            type: IS_LOADING,
            isLoading
        })
        dispatch({
            type: DECREASE_LOADING_ITEMS
        })
    } else {
        dispatch({
            type: DECREASE_LOADING_ITEMS
        })
    }


}

export const displayErrors = (errors) => {
    Object.keys(errors).forEach(key => {
        if (typeof (errors[key]) === 'object') {
            displayErrors(errors[key])
        } else {
            toast.error(errors[key]);
        }
    });
}

export const saveHistoryToReducer = (history) => dispatch => {
    dispatch({
        type: SAVE_HISTORY,
        history
    })
}

export const logout = () => (dispatch, getState) => {
    const history = getState().app.history;
    dispatch(clearUser());

    history.push('/login')
    window.location.reload();
}

export const getApplicationSettings = () => (dispatch, getState) => {
    const user = getState().app.user;

    dispatch(isLoading(true));
    Api.get('application/settings').then(res => {
        if (res.success) {
            dispatch(saveSettings(res.documents))
        } else {
            if (res.errors && user) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        console.error(err)
    })
}

const saveSettings = (settings) => dispatch => {
    dispatch({
        type: SAVE_SETTINGS,
        settings
    })
}