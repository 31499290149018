import Api from '../../../services/api';
import { isLoading, displayErrors } from '../../../application/actions/application.actions';

const NAMESPACE = 'ADMIN';

export const SET_SELLERS = `SET_SELLERS_${NAMESPACE}`;

export const getSellers = (searchQuery) => (dispatch, getState) => {
    const params = {...getState().admin.params};
    params.type = "salesman";
    if (searchQuery && searchQuery !== "" && params) {
        params[`name[orin]`] = searchQuery;
    }

    dispatch(isLoading(true));

    Api.get('admin/user', params).then(res => {
        if (res.success) {
            dispatch(setSellers(res.documents));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

const setSellers = (sellers) => dispatch => {
    dispatch({
        type: SET_SELLERS,
        sellers
    })
}

