import { SAVE_USER, CLEAR_USER } from '../actions/login.action';
import { IS_LOADING, SAVE_HISTORY, INCREASE_LOADING_ITEMS, DECREASE_LOADING_ITEMS, SAVE_SETTINGS } from '../actions/application.actions';

const defaultState = {
    loadingItems: 0,
    isLoading: false,
    history: null,
    user: null,
    settings: null
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SAVE_USER:
            return {
                ...state,
                user: action.user
            }
        case CLEAR_USER:
            return {
                ...state,
                user: null
            }
        case SAVE_HISTORY:
            return {
                ...state,
                history: action.history
            }
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        case INCREASE_LOADING_ITEMS:
            return {
                ...state,
                loadingItems: state.loadingItems + 1
            }
        case DECREASE_LOADING_ITEMS:
            return {
                ...state,
                loadingItems: state.loadingItems - 1
            }
        case SAVE_SETTINGS:
            return {
                ...state,
                settings: action.settings
            }
        default:
            return state;

    }
}