import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import TextInput from '../../../application/components/formComponents/TextInput';

const MailBox = ({ onSubmit,
    mailConfig: { name, host, port, auth: { user, pass }, themeColor }
}) => {
    const { t } = useTranslation();

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                name: (name) ? name : '',
                host: (host) ? host : '',
                user: (user) ? user : '',
                port: (port) ? port : '',
                password: (pass) ? pass : '',
                themeColor: (themeColor) ? themeColor : '',
            }}
            validationSchema={Yup.object({
                name: Yup.string()
                    .required(t(`fieldRequired`))
                ,
                user: Yup.string()
                    .required(t(`fieldRequired`))
                ,
                host: Yup.string()
                    .required(t(`fieldRequired`))
                ,
                port: Yup.string()
                    .required(t(`fieldRequired`))
                ,
                password: Yup.string()
                    .required(t(`fieldRequired`))
                ,
            })}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
                onSubmit(values)
            }}
        >
            {formik => (
                <React.Fragment>
                    <div className="col-12 mt-4">
                        <TextInput
                            id="name"
                            label={t('name')}
                            type="text"
                            onChange={formik.setFieldValue}
                            value={formik.values.name}
                            errors={formik.errors.name}
                        />
                    </div>

                    <div className="col-12 mt-4">
                        <TextInput
                            id="user"
                            label={t('user')}
                            type="text"
                            onChange={formik.setFieldValue}
                            value={formik.values.user}
                            errors={formik.errors.user}
                        />
                    </div>

                    <div className="col-12 mt-4">
                        <TextInput
                            id="host"
                            label="Host"
                            type="text"
                            onChange={formik.setFieldValue}
                            value={formik.values.host}
                            errors={formik.errors.host}
                        />
                    </div>

                    <div className="col-12 mt-4">
                        <TextInput
                            id="port"
                            label="Port"
                            type="text"
                            onChange={formik.setFieldValue}
                            value={formik.values.port}
                            errors={formik.errors.port}
                        />
                    </div>

                    <div className="col-12 mt-4">
                        <TextInput
                            id="password"
                            label={t('password')}
                            type="text"
                            onChange={formik.setFieldValue}
                            value={formik.values.password}
                            errors={formik.errors.password}
                        />
                    </div>
                    <div className="col-12 mt-4">
                        <TextInput
                            id="themeColor"
                            label={t('themeColor')}
                            type="text"
                            onChange={formik.setFieldValue}
                            value={formik.values.themeColor}
                            errors={formik.errors.themeColor}
                        />
                    </div>

                    <div className="col-12 text-center mt-5">
                        <button onClick={formik.handleSubmit} id="adminClientsSave" className="btn btn--primary">{t('saveAction')}</button>
                    </div>

                </React.Fragment>
            )}
        </Formik>
    )
}

export default MailBox;