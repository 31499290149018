import { toast } from 'react-toastify';
import { displayErrors, isLoading } from '../../../application/actions/application.actions';
import { setProducts } from '../../../products/actions/products.actions';
import Api from '../../../services/api';
import { INCREASE_OFFSET, DECREASE_OFFSET, RESET_OFFSET, SET_OFFSET } from "../pricing/pricing.actions"

const NAMESPACE = 'ADMIN';

export const SET_DISCOUNT_GROUPS = `SET_DISCOUNT_GROUPS_${NAMESPACE}`;
export const CLEAR_DISCOUNT_GROUP = `CLEAR_DISCOUNT_GROUP_${NAMESPACE}`;
export const SET_DISCOUNT_GROUP = `SET_DISCOUNT_GROUP_${NAMESPACE}`;
export const SET_DISCOUNT_GROUP_ERRORS = `SET_DISCOUNT_GROUP_ERRORS_${NAMESPACE}`;

export const createDiscountGroup = (data) => (dispatch, getState) => {
    dispatch(isLoading(true));
    const history = getState().app.history;

    Api.post(`discountGroup/discountGroup`, data).then(res => {
        if (res.success) {
            toast.info('Rabat został utworzony');
            history.push('/admin/discountGroups');
            window.location.reload();

        } else {
            if (res.errors) {
                displayErrors(res.errors);
                dispatch(setDiscountGroupErrors(res.errors))
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const editDiscountGroup = (id, data) => (dispatch, getState) => {
    dispatch(isLoading(true));
    const history = getState().app.history;

    Api.put(`discountGroup/discountGroup/${id}`, data).then(res => {
        if (res.success) {
            toast.info('Rabat został zaktualizowany');
            history.push('/admin/discountGroups');
            window.location.reload();
        } else {
            if (res.errors) {
                displayErrors(res.errors);
                dispatch(setDiscountGroupErrors(res.errors))
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const deleteDiscountGroup = (id) => (dispatch) => {
    dispatch(isLoading(true));

    Api.delete(`discountGroup/discountGroup/${id}`).then(res => {
        if (res.success) {
            toast.info('Rabat został usunięty');
            dispatch(getDiscountGroups());
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const getDiscountGroups = () => (dispatch, getState) => {
    dispatch(isLoading(true));
    const allParams = { ...getState().admin.params };
    const params = {
        offset: allParams.offset
    }
    Api.get(`discountGroup/discountGroup`, params).then(res => {
        if (res.success) {
            dispatch(setDiscountGroups(res.documents, res.total));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const setDiscountGroups = (discountGroups, discountGroupsTotal) => dispatch => {
    dispatch({
        type: SET_DISCOUNT_GROUPS,
        discountGroups,
        discountGroupsTotal
    })
}

export const clearDiscountGroup = () => dispatch => {
    dispatch({
        type: CLEAR_DISCOUNT_GROUP
    })
}

export const getDiscountGroup = (id) => (dispatch) => {
    dispatch(isLoading(true));

    Api.get(`discountGroup/discountGroup/${id}`).then(res => {
        if (res.success) {
            dispatch(setDiscountGroup(res.document));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const setDiscountGroup = (discountGroup) => dispatch => {
    dispatch({
        type: SET_DISCOUNT_GROUP,
        discountGroup
    })
}

export const discountGroupsNextPage = () => (dispatch) => {
    dispatch({
        type: INCREASE_OFFSET
    })
    dispatch(getDiscountGroups())
}

export const discountGroupsPrevPage = () => (dispatch) => {
    dispatch({
        type: DECREASE_OFFSET
    })
    dispatch(getDiscountGroups())
}

export const resetOffset = () => dispatch => {
    dispatch({
        type: RESET_OFFSET
    })
}

export const goToDiscountGroupsPage = (page) => (dispatch, getState) => {
    const limit = getState().admin.params.limit;
    const newOffset = (page - 1) * limit;

    dispatch({
        type: SET_OFFSET,
        newOffset
    })

    dispatch(getDiscountGroups())
}

export const getProductsDiscountGroup = (query) => dispatch => {
    dispatch(isLoading(true));
    // const params = { ...getState().products.params };
    let params = {
        limit: 15
    };

    if (query && query !== '') {
        params[`name.pl[orin]`] = query;
        params[`name.en[orin]`] = query;
    }

    Api.get('product/variant', params).then(res => {
        if (res.success) {
            dispatch(setProducts(res.documents, res.total));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const setDiscountGroupErrors = (errors) => dispatch => {
    dispatch({
        type: SET_DISCOUNT_GROUP_ERRORS,
        errors
    })
}