import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Search from '../../application/components/search/Search';
import TableList from '../../application/components/tableList/TableList';

const Clients = (props) => {
    const { getClients, clients } = props;
    const { t } = useTranslation();

    useEffect(() => {
        getClients();
    }, [getClients])

    const handleClientSearch = (query) => {
        if (query.length === 0)  getClients("");
        getClients(query);
    }

    return (
        <section id="clients" className="clients content">
            <div className="container">
                <div className="row mt-5">
                    <div className="col-12 mt-5">
                        <h1>{t('clients')}</h1>
                    </div>
                    <div className="col-12 mt-5">
                        <Search
                            onChange={(query) => handleClientSearch(query)}
                        />
                    </div>
                    <div className="col-12 mt-5">
                        <TableList
                            values={clients}
                            simpleList={true}
                            editRoute={'client'}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Clients