import { SET_EXTERNAL_CLIENTS, SET_CLIENT, CLEAR_CLIENT, SET_PRODUCT_CATEGORY_LIST } from '../actions/clients/adminClient.actions';
import { SET_CLIENTS } from '../actions/clients/adminClients.actions';
import { SET_SELLERS } from '../actions/sellers/adminSellers.actions';
import { SET_EXTERNAL_SELLERS, SET_SELLER, CLEAR_SELLER } from '../actions/sellers/adminSeller.actions';

import {
    SET_PRICINGS,
    CLEAR_PRICING,
    SET_PRICING,
    INCREASE_OFFSET,
    DECREASE_OFFSET,
    SET_OFFSET,
    RESET_OFFSET,
    SET_PRICING_ERRORS
} from '../actions/pricing/pricing.actions';

import {
    SET_DISCOUNT_GROUPS,
    CLEAR_DISCOUNT_GROUP,
    SET_DISCOUNT_GROUP,
    SET_DISCOUNT_GROUP_ERRORS
} from '../actions/discountGroup/discountGroup.actions';

const defaultState = {
    params: {
        select: 'name,active,email',
        limit: 15,
        offset: 0,
        // active: true
    },
    externalClients: null,
    externalSellers: null,
    productCategoryList: null,
    clients: null,
    client: {},
    sellers: null,
    seller: {},
    pricingsTotal: null,
    pricings: null,
    pricing: null,
    pricingErrors: {},

    discountGroupsTotal: null,
    discountGroups: null,
    discountGroup: null,
    discountGroupErrors: {},
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_EXTERNAL_CLIENTS:
            return {
                ...state,
                externalClients: action.externalClients
            }
        case SET_CLIENTS:
            return {
                ...state,
                clients: action.clients
            }
        case SET_CLIENT:
            return {
                ...state,
                client: action.client
            }
        case CLEAR_CLIENT:
            return {
                ...state,
                client: {}
            }
        case SET_EXTERNAL_SELLERS:
            return {
                ...state,
                externalSellers: action.externalSellers
            }
        case SET_SELLERS:
            return {
                ...state,
                sellers: action.sellers
            }
        case SET_SELLER:
            return {
                ...state,
                seller: action.seller
            }
        case CLEAR_SELLER:
            return {
                ...state,
                seller: {}
            }
        case SET_PRICINGS:
            return {
                ...state,
                pricings: action.pricings,
                pricingsTotal: action.pricingsTotal
            }
        case SET_PRICING:
            return {
                ...state,
                pricing: action.pricing
            }
        case SET_PRICING_ERRORS:
            return {
                ...state,
                pricingErrors: action.errors
            }
        case CLEAR_PRICING:
            return {
                ...state,
                pricing: null
            }
        case INCREASE_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: state.params.offset + state.params.limit
                }
            }
        case DECREASE_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: state.params.offset - state.params.limit
                }
            }
        case SET_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: action.newOffset
                }
            }
        case RESET_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: 0
                }
            }

        case SET_DISCOUNT_GROUPS:
            return {
                ...state,
                discountGroups: action.discountGroups,
                discountGroupsTotal: action.discountGroupsTotal
            }
        case SET_DISCOUNT_GROUP:
            return {
                ...state,
                discountGroup: action.discountGroup
            }
        case SET_DISCOUNT_GROUP_ERRORS:
            return {
                ...state,
                discountGroupErrors: action.errors
            }
        case CLEAR_DISCOUNT_GROUP:
            return {
                ...state,
                discountGroup: null
            }
        case SET_PRODUCT_CATEGORY_LIST:
            return {
                ...state,
                productCategoryList: action.productCategoryList
            }
        default:
            return state;

    }
}