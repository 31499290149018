import React from 'react';
import { useLocation} from "react-router-dom";

import { Link } from 'react-router-dom';

const HeaderSingleCell = (props) => {
    const { icon, title, path, mobile } = props;
    let active = false;
    const location = useLocation();
    
    if(location.pathname === path){
        active = true;
    }

    if (mobile) {
        return (
            <div className={`col-12 ${(active) ? 'menu__item--active' : 'menu__item'} text-center`}>
                <Link to={path} className="py-4 d-block menu__link">
                    <img className="menu__icon" src={icon} alt="" />
                    <p className="menu__title mt-2">{title}</p>
                </Link>
            </div>
        )
    }
    return (
        <div className={`col-auto ${(active) ? 'menu__item--active' : 'menu__item'} text-center`}>
            <Link to={path} className="py-4 d-block menu__link">
                <img className="menu__icon" src={icon} alt="" />
                <p className="menu__title mt-2">{title}</p>
            </Link>
        </div>
    )
}

export default HeaderSingleCell;