import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from './assets/translations/en.json'
import plTranslation from './assets/translations/pl.json'
import skTranslation from './assets/translations/sk.json'

const resources = {
    en: {
        translation: enTranslation
    },
    pl: {
        translation: plTranslation
    },
    sk: {
        translation: skTranslation
    },
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: (localStorage.getItem('language') ? localStorage.getItem('language') : "pl"),

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;