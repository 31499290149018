import React from 'react';


const NotFound = ({ }) => {
    return (
        <section id="invoice" className="invoice content d-flex justify-content-center align-items-center">
            <h1>
                Nie znaleziono strony
            </h1>
        </section>
    )
}

export default NotFound;