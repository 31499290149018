import React from 'react';

import './productPrice.css';

const ProductPrice =
    ({ discountedPrice, price, currency, showPrices, priceBeforeDiscount, size }) => {
        // price = 1;
        const displayPrice = () => {
            if (typeof price === "undefined") return;
            if (!showPrices && priceBeforeDiscount) {
                return <p className={`${(size === 'small') ? 'price__small' : 'price__main'}`}>{priceBeforeDiscount.toFixed(2)} {currency}</p>
            }
            return <p className={`${(size === 'small') ? 'price__small' : 'price__main'}`}> {price.toFixed(2)} {currency}</p >

        }

        return (
            <div className="price">
                {displayPrice()}

                {(discountedPrice !== null && discountedPrice !== undefined) && (discountedPrice > price) && !showPrices && <p className="price__discount ml-4">{discountedPrice.toFixed(2)} {currency}</p>}
            </div>
        )
    }

export default ProductPrice;