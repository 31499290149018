import React from 'react';
import { useTranslation } from 'react-i18next';

import './confirmationModal.css';

const ConfirmationModal = ({ text, onConfirm, onCancel }) => {
    const { t } = useTranslation();

    return (
        <div className="confirmationModalContainer">
            <div className="confirmationModal">
                <p>
                    {text}
                </p>
                <div className="row">
                    <div className="col-6 text-center mt-5">
                        <button id="adminClientsSave" className="btn btn--primary"
                            onClick={() => onCancel()}
                        >{t('cancel')}</button>
                    </div>
                    <div className="col-6 text-center mt-5">
                        <button id="adminClientsSave" className="btn btn--primary"
                            onClick={() => onConfirm()}
                        >{t('confirm')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal;