import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';


import './pricing.css';

import Chips from '../../../application/components/formComponents/Chips';
import TextInput from '../../../application/components/formComponents/TextInput';

import ClientPicker from '../../components/pricing/ClientPicker.component';
import CategoryPicker from '../../components/pricing/CategoryPicker.component';
import SelectedCategories from '../../components/pricing/SelectedCategories.component';
import ProductPicker from '../../components/pricing/ProductPicker.component';
import SelectedProducts from '../../components/pricing/SelectedProducts.component';

import { getClients } from '../../actions/clients/adminClients.actions';
import { createPricing, getPricing, editPricing, clearPricing, getProductsPricing, setPricingErrors } from '../../actions/pricing/pricing.actions';
import { getCategoriesTree, getProducts, setProductsQuery, clearProducts } from '../../../products/actions/products.actions';


const PricingContainer = ({ getClients, clients, getCategoriesTree, productCategoriesTree, getProductsPricing, products, createPricing, getPricing, pricing, editPricing, clearPricing, clearProducts, setPricingErrors, pricingErrors }) => {
    const params = useParams();
    const { t } = useTranslation();

    const [clientsChips, setClientsChips] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [overallDiscount, setOverallDiscount] = useState(0);
    const [allowOtherProducts, setAllowOtherProducts] = useState(true);
    // const [pricingName, setPricingName] = useState('');

    useEffect(() => {
        getClients();
    }, [getClients])
    useEffect(() => {
        getCategoriesTree();
    }, [getCategoriesTree])
    useEffect(() => {
        getProductsPricing();
    }, [getProductsPricing])
    useEffect(() => {
        return () => {
            clearPricing();
            clearProducts();
        }
    }, [clearPricing, clearProducts])

    useEffect(() => {
        if (pricing) {
            // setPricingName(_.get(pricing, 'name'))
            setOverallDiscount(_.get(pricing, 'totalDiscount'))
            setSelectedCategories(_.get(pricing, 'categories'))
            setSelectedProducts(_.get(pricing, 'products'))
            setAllowOtherProducts(_.get(pricing, 'allowOtherProducts'))
            const clients = _.get(pricing, 'companies');
            if (clients) {
                clients.forEach(client => {
                    handleClientAdd(client)
                })
            }
        }
    }, [pricing])

    useEffect(() => {
        if (params && params.id) {
            getPricing(params.id);
        }
    }, [getPricing])



    const handleClientAdd = (client) => {
        if (client) {
            //prevent duplicates
            if (
                _.find(clientsChips, (o) => {
                    return o._id === client._id
                })
            ) {
                //do nothing
            } else {
                setClientsChips(prev => [...prev, client]);
            }
        }
    }

    const handleDeleteClientChip = (client) => {
        setClientsChips(clientsChips.filter((e) => (e._id !== client._id)))
    }

    const renderClientChips = () => {
        return clientsChips.map(chip => {
            return <Chips
                key={chip.name}
                item={chip}
                onClick={handleDeleteClientChip}
            />
        })
    }

    const selectCategory = (category) => {

        if (category) {
            //prevent duplicates
            if (
                _.find(selectedCategories, (o) => {
                    return o.foreignId === category._id
                })
            ) {
                //do nothing
            } else {
                let newCategory = {
                    foreignId: category.foreignId,
                    name: _.get(category, 'name')
                }

                setSelectedCategories(prev => [...prev, newCategory]);
            }
        }


    }
    const deleteCategory = (category) => {
        setSelectedCategories(
            selectedCategories => selectedCategories.filter(item => item.foreignId !== category.foreignId)
        );
    }

    const handleChangeCategoryDiscount = (i, value) => {
        const newSelectedCategories = [...selectedCategories];

        setSelectedCategories([
            ...newSelectedCategories.slice(0, i),
            {
                ...newSelectedCategories[i],
                discount: value
            },
            ...newSelectedCategories.slice(i + 1)
        ]);
    }

    const handleProductAdd = (product) => {
        if (product) {
            //prevent duplicates
            if (
                _.find(selectedProducts, (o) => {
                    return o.foreignId === product._id
                })
            ) {
                //do nothing
            } else {
                let newProduct = {
                    foreignId: _.get(product, 'foreignId'),
                    name: _.get(product, 'name')
                }
                setSelectedProducts(prev => [...prev, newProduct]);
            }
        }
    }

    const handleChangeProductDiscount = (i, value) => {
        const newSelectedProducts = [...selectedProducts];

        setSelectedProducts([
            ...newSelectedProducts.slice(0, i),
            {
                ...newSelectedProducts[i],
                discount: value
            },
            ...newSelectedProducts.slice(i + 1)
        ]);
    }
    const handleChangeProductPriceCurrency = (i, value, selectedCurrency) => {
        const newSelectedProducts = [...selectedProducts];
        if (value) {
            let price = {};
            price[selectedCurrency] = parseFloat(value);

            setSelectedProducts([
                ...newSelectedProducts.slice(0, i),
                {
                    ...newSelectedProducts[i],
                    priceCurrency: {
                        ...newSelectedProducts[i].priceCurrency = price
                    }
                },
                ...newSelectedProducts.slice(i + 1)
            ]);
        } else {
            setSelectedProducts([
                ...newSelectedProducts.slice(0, i),
                {
                    ...newSelectedProducts[i],
                    priceCurrency: null
                },
                ...newSelectedProducts.slice(i + 1)
            ]);
        }

    }

    const handleDeleteSelectedProduct = (i) => {
        const newSelectedProducts = [...selectedProducts];

        setSelectedProducts([
            ...newSelectedProducts.slice(0, i),
            ...newSelectedProducts.slice(i + 1)
        ]);
    }

    const checkCategories = (categories) => {
        let isValid = true;
        categories.forEach(cat => {
            if (!cat.discount) {
                isValid = false;
            }
        })

        if (!isValid) {
            toast.error('Uzupełnij rabat w wybranych kategoriach');
        }

        return isValid;
    }
    const checkProducts = (products) => {
        let isValid = true;
        products.forEach(p => {
            if (!p.discount && !p.priceCurrency) {
                isValid = false;
            }
        })

        if (!isValid) {
            toast.error('Uzupełnij rabat lub cenę w wybranych produktach');
        }

        return isValid;
    }

    const handlePricingSubmit = (pricingName) => {
        const data = {
            name: pricingName,
            totalDiscount: overallDiscount,
            companies: clientsChips,
            products: selectedProducts,
            categories: selectedCategories,
            allowOtherProducts: allowOtherProducts
        }

        const categoriesValid = checkCategories(data.categories);
        const productsValid = checkProducts(data.products);

        if (!categoriesValid || !productsValid) return;

        // return;
        if (params && params.id) {
            editPricing(params.id, data);
        } else {
            createPricing(data);
        }
    }


    return (

        <section id="pricing" className="pricing content"
            style={{ width: '100%' }}
        >
            <div className="container">
                <div className="row">
                    <div className="col">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                name: (pricing && pricing.name) ? pricing.name : '',
                            }}
                            validationSchema={Yup.object({
                                name: Yup.string()
                                    .required(`Nazwa cennika jest wymagana`)
                                ,
                            })}
                            validateOnChange={false}
                            validateOnBlur={false}
                            enableReinitialize={true}
                            onSubmit={(values) => {
                                handlePricingSubmit(values.name);
                            }}
                        >
                            {formik => (
                                <React.Fragment>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className=" col-12 table-responsive rounded-box p-5">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h1>{t('pricing')}</h1>
                                                    </div>
                                                </div>


                                                <div className="row mt-5 align-items-center justify-content-between">
                                                    <TextInput
                                                        id="name"
                                                        label={t('pricingName')}
                                                        type="text"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.name}
                                                        errors={formik.errors.name}
                                                    />
                                                </div>

                                                <ClientPicker
                                                    getClients={getClients}
                                                    clients={clients}
                                                    handleClientAdd={(client) => handleClientAdd(client)}
                                                    errors={_.get(pricingErrors, 'companies')}
                                                />

                                                <div className='row'>
                                                    {renderClientChips()}
                                                </div>

                                                <CategoryPicker
                                                    productCategoriesTree={productCategoriesTree}
                                                    selectCategory={(category) => selectCategory(category)}
                                                    selectedCategories={selectedCategories}
                                                />


                                                <ProductPicker
                                                    getProducts={getProductsPricing}
                                                    products={products}
                                                    handleProductAdd={(product) => handleProductAdd(product)}
                                                />

                                                <div className="col-auto d-flex align-items-center mt-3 pt-4 mt-lg-0"
                                                >
                                                    <input type="checkbox"
                                                        checked={allowOtherProducts}
                                                        onChange={(e) => setAllowOtherProducts(e.target.checked)}
                                                    ></input>
                                                    <label htmlFor="pricingCheckbox" className="mb-0">{t('toggleOtherProducts')}</label>
                                                </div>


                                            </div>
                                        </div>


                                        <div className="col-12 mt-50">
                                            <h4>{t('overallDiscount')}</h4>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <input type="number" name="pricing__total" className="mt-4"
                                                        value={overallDiscount}
                                                        onChange={(e) => setOverallDiscount(e.target.value)}
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>

                                        <SelectedCategories
                                            selectedCategories={selectedCategories}
                                            deleteCategory={deleteCategory}
                                            handleChangeCategoryDiscount={(i, value) => handleChangeCategoryDiscount(i, value)}
                                        />

                                        <SelectedProducts
                                            selectedProducts={selectedProducts}
                                            handleChangeProductDiscount={(i, value) => handleChangeProductDiscount(i, value)}
                                            handleChangeProductPriceCurrency={(i, value, selectedCurrency) => handleChangeProductPriceCurrency(i, value, selectedCurrency)}
                                            handleDeleteSelectedProduct={(i) => handleDeleteSelectedProduct(i)}
                                        />

                                    </div>
                                    <div className="row mt-5 justify-content-center">
                                        <div className="col-auto">
                                            <button id="pricingSave" className="btn btn--primary"
                                                onClick={formik.handleSubmit}
                                            >{t('saveAction')}</button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </Formik>


                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (store) => ({
    pricing: store.admin.pricing,
    clients: store.admin.clients,
    productCategoriesTree: store.products.productCategoriesTree,
    products: store.products.products,
    pricingErrors: store.admin.pricingErrors

});

const mapDispatchToProps = (dispatch) => {
    return {
        getClients: (query) => dispatch(getClients(query)),
        getCategoriesTree: () => dispatch(getCategoriesTree()),
        getProducts: (id, noLimit) => dispatch(getProducts(id, noLimit)),
        getProductsPricing: (query) => dispatch(getProductsPricing(query)),
        setProductsQuery: (query) => dispatch(setProductsQuery(query)),
        createPricing: (data) => dispatch(createPricing(data)),
        getPricing: (id) => dispatch(getPricing(id)),
        editPricing: (id, data) => dispatch(editPricing(id, data)),
        clearPricing: () => dispatch(clearPricing()),
        clearProducts: () => dispatch(clearProducts()),
        setPricingErrors: (errors) => dispatch(setPricingErrors(errors)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingContainer);