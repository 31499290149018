import React from 'react';
import { useTranslation } from 'react-i18next';

import AutoComplete2 from '../../../application/components/formComponents/AutoComplete2';

const ClientPicker = ({ getClients, clients, handleClientAdd, errors }) => {
    const { t } = useTranslation();

    const renderErrors = () => {
        if (!errors) return;
        for (const [key, value] of Object.entries(errors)) {
            return <div
                style={{ color: 'red' }}
                className="error__message">{Object.values(value)}</div>
        }
    }

    return (
        <React.Fragment>
            <div className="row mt-5 align-items-center justify-content-between">
                <div className="col-auto">
                    <h4>{t('selectClients')}</h4>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <AutoComplete2
                        label={t('clients')}
                        options={(clients) ? clients : null}
                        onChange={(client) => handleClientAdd(client)}
                        getFunction={getClients}
                    />
                    {renderErrors()}
                </div>
            </div>
        </React.Fragment>
    )
}

export default ClientPicker;