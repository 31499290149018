import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProductCategoriesTreeItem from './ProductCategoriesTreeItem.component';

const ProductCategories = ({ productCategoriesTree, resetProductsCategory }) => {
    const {t} = useTranslation();

    const renderTreeItems = () => {
        if (productCategoriesTree) {
            return productCategoriesTree.map(e => {
                const hrefId = e._id.replace(/[0-9]/g, '');
                return (
                    <ProductCategoriesTreeItem
                        key={e._id}
                        id={e._id}
                        hrefId={hrefId}
                        name={e.name.pl}
                        children={e.children}
                    />
                )
            })
        }
    }

    return (
        <ul className="nav flex-column d-none d-md-flex nav--desktop">
            <li className={`nav__item ${(window.location.pathname === `/products`) ? 'active' : 'null'}`}>
                <Link className="nav__link" to="/products"
                    onClick={() => resetProductsCategory()}
                >{t('allCategories')}</Link>
            </li>
            {renderTreeItems()}
        </ul>
    )
}

export default ProductCategories;