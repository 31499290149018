import React from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import ProductPrice from './ProductPrice.component';

const SelectVariant = ({ placeholder, options, currency, onChange, showPrices }) => {
    const { t } = useTranslation();

    const renderOptions = () => {
        if (options) {
            return options.map((o, i) => {
                return (
                    <div className="dropdown-item d-flex justify-content-between align-items-center" key={i}
                        onClick={() => onChange(o)}
                    >
                        {o.avatar ? <img style={{ height: 40, border: "3px solid black" }} src={o.avatar} /> : null}

                        <div className="justify-content-center align-items-center" style={{ overflow: 'auto', maxWidth: '80%' }}>
                            <div className="price__label">{t('title')}</div>
                            <div style={{ overflow: 'auto' }}>  {_.get(o, `name[${localStorage.getItem('language')}]`, _.get(o, "name.pl"))} </div>
                        </div>


                        <div className="justify-content-end">
                            <p className="price__label">{t('price')}</p>
                            <ProductPrice
                                discountedPrice={_.get(o, 'discountedPrice')}
                                priceBeforeDiscount={_.get(o, 'priceBeforeDiscount')}
                                price={_.get(o, 'price')}
                                currency={currency}
                                showPrices={showPrices}
                                size={"small"}
                            />
                        </div>
                    </div>
                )
            })
        }
    }

    return (
        <div className={`select d-block d-xl-block`}>
            {/* {label &&
                <label>{label}</label>
            } */}
            <div className="dropdown dropdown--select ">
                <div className={`dropdown-toggle`} type="button" id="dropdownSelect" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    {placeholder}
                </div>
                <div className="dropdown-menu" aria-labelledby="dropdownSelect" >
                    {renderOptions()}
                </div>
            </div>
        </div>
    )
}

export default SelectVariant;