import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

import Check from '../../assets/icons/check.svg';
import Zamknij from '../../assets/icons/zamknij.svg';

const Invoices = ({ invoices }) => {
    const { t } = useTranslation();

    const invoiceStatus = (status) => {
        if (status === 1) {
            return (
                <td style={{ width: '10%' }}>
                    <div className="invoice__status invoice__status--paid">
                        <img src={Check} alt="" className="mr-4" />
                        <span>{t('paid')}</span>
                    </div>
                </td>
            )
        }

        return (
            <td style={{ width: '10%' }}>
                <div className="invoice__status invoice__status">
                    <img src={Zamknij} alt="" className="mr-4" />
                    <span>{t('unpaid')}</span>
                </div>
            </td>
        )
    }

    const renderInvoices = () => {
        if (!invoices) return;

        return invoices.map((inv, i) => {
            return (
                <tr key={_.get(inv, '_id')} >
                    <td >
                        <Link to={`/invoice/${_.get(inv, '_id')}`}>{i + 1}. {t('invoice')} {_.get(inv, 'number')}</Link>
                    </td>
                    <td className="d-flex justify-content-center align-items-center">
                        <Moment format="DD/MM/YYYY">
                            {_.get(inv, 'createdAt')}
                        </Moment>
                    </td>
                    {invoiceStatus(_.get(inv, 'status'))}
                </tr >
            )
        })
    }

    return (
        <div className="col-12 mt-5">
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">{t('number')}</th>
                            <th scope="col">{t('creationDate')}</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderInvoices()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Invoices;