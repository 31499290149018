import { combineReducers } from 'redux';
import applicationReducer from '../application/reducer/application.reducer';
import adminReducer from '../admin/reducers/admin.reducer';
import clientReducer from '../clients/reducer/clients.reducer';
import productsReducer from '../products/reducers/products.reducer';
import productReducer from '../product/reducers/product.reducer';
import cartReducer from '../cart/reducers/cart.reducer';
import sellerOrdersReducer from '../sellerOrders/reducers/sellerOrders.reducer';
import sellerOrderReducer from '../sellerOrder/reducers/sellerOrder.reducer';
import ordersReducer from '../orders/reducers/orders.reducer';
import orderReducer from '../order/reducers/order.reducer';
import invoicesReducer from '../invoices/reducers/invoices.reducer';
import invoiceReducer from '../invoice/reducers/invoice.reducer';
import pricingReducer from '../pricing/reducers/pricing.reducer';

export default combineReducers({
    app: applicationReducer,
    admin: adminReducer,
    clients: clientReducer,
    products: productsReducer,
    product: productReducer,
    cart: cartReducer,
    sellerOrders: sellerOrdersReducer,
    sellerOrder: sellerOrderReducer,
    orders: ordersReducer,
    order: orderReducer,
    invoices: invoicesReducer,
    invoice: invoiceReducer,
    pricing: pricingReducer
})