import {
    SET_ORDER,
    CLEAR_ORDER
} from '../actions/sellerOrder.actions';

const defaultState = {
    order: null
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_ORDER:
            return {
                ...state,
                order: action.order
            }
        case CLEAR_ORDER:
            return {
                ...state,
                order: null
            }
        default:
            return state;

    }
}