import React from 'react';
import _ from 'lodash'
import { useTranslation } from 'react-i18next';
import ZamknijWhite from '../../../assets/icons/zamknij-white.svg'

const SelectedProducts = ({ selectedProducts, handleDeleteSelectedProduct }) => {
    const { t } = useTranslation();

    const renderProducts = () => {
        if (!selectedProducts) return null;

        return selectedProducts.map((prod, i) => {
            // let selectedPricing;
            return (<tr key={`product${i}`}>
                <th scope="col">{_.get(prod, "name.pl", "-")}</th>
                <th scope="col">
                    <div className="client__action client__action--delete" onClick={() => handleDeleteSelectedProduct(i)}>
                        <img src={ZamknijWhite} alt="" />
                    </div>
                </th>
            </tr>)
        })
    }

    if (!selectedProducts || selectedProducts.length === 0) return null;

    return (
        <React.Fragment>
            <div className="col-12 mt-4">
                <div className="table-responsive">
                    <table className="table table-striped discounts__table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">{t('deleteAction')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderProducts()}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SelectedProducts;