import React, { useState } from 'react';
import _ from 'lodash';

const AutoComplete = ({ label, errors, options, onChange, getFunction, multiselect }) => {
    const [value, setValue] = useState('');
    const [showResult, setShowResult] = useState(false);
    const [debounced, setDebounced] = useState((value) => _.debounce((value) => getFunction(value), 500));

    const handleOnChange = (inputValue) => {
        setValue(inputValue);
        debounced(inputValue)
    };

    const handleOnSelect = (o) => {
        setValue("");
        onChange(o);
        setShowResult(false)
    }

    const renderOptionName = (o) => {
        const language = localStorage.getItem('language');

        if (o.name[language]) {
            return o.name[language];
        } else if (o.name.pl) {
            return o.name.pl
        } else if (o.name) {
            return o.name
        }

        return "";
    }

    const renderOptions = () => {
        if (!options) return;

        return options.map((o, i) => {
            return (
                <div
                    key={i}
                    style={{ width: '100%', height: 50, border: "1px solid rgba(0,0,0,0.2)", cursor: "pointer", paddingLeft: 16 }}
                    className="d-flex align-items-center"
                    onClick={() => handleOnSelect(o)}

                >
                    {renderOptionName(o)}
                </div>
            )
        })
    }


    return (
        <div className={`select d-block d-xl-block ${errors ? 'input--error' : ''}`}>
            <label>{label}</label>
            <div className="dropdown dropdown--select ">
                <input
                    value={value}
                    type="text"
                    className={`dropdown-toggle ${errors ? 'border--error' : 'border--light'}`}
                    onChange={(e) => handleOnChange(e.target.value)}
                    onFocus={() => setShowResult(true)}
                    onBlur={() => setTimeout(() => setShowResult(false), 150)}
                />

                {showResult &&
                    <div style={{
                        position: 'absolute',
                        background: 'white',
                        width: '100%',
                        maxHeight: 300,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        zIndex: 100
                    }}>
                        {renderOptions()}
                    </div>
                }

            </div>
            {errors && <div className="error__message">{errors}</div>}

        </div>
    )
}

export default AutoComplete;