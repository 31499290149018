import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import configureStore from './config/configureStore';
import App from './application/containers/App';

import './assets/css/style.css'

import './assets/css/bootstrap-datepicker.standalone.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './i18n';

export const store = configureStore();


ReactDOM.render(
  <Provider store={store}>
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
    />
    <App />
  </Provider>
  ,
  document.getElementById('root')
);
