import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerComponent = ({ setDate, date }) => {

    return (
        <React.Fragment>
            <DatePicker className={{ border: '1px solid black' }} selected={date} onChange={date => setDate(date)} dateFormat={'dd/MM/yyyy'} />
        </React.Fragment>
    )
}

export default DatePickerComponent;