import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';


const CartAddress = ({ addresses, name, handleSelect, selectedIndex }) => {
    const { t } = useTranslation();

    const renderAddresses = () => {
        if (!addresses || !name) return;

        return addresses.map((a, i) => {
            let selectedIndex = parseInt(localStorage.getItem('selectedAddressIndex'));
            return (
                <div className="col-md-6 mt-4" key={i}>
                    <div className="address rounded-box p-5">
                        <input type="radio" className="address__radio" name="cartAddress" value="1" checked={(selectedIndex === i) ? true : false} onChange={() => handleSelect(i)} />
                        <p className="address__label mb-4">{t('address')} {i + 1}</p>
                        <p className="address__content">{name}
                            <br /> {_.get(a, 'street')} {_.get(a, 'streetNumber')}<br />
                            {_.get(a, 'postCode')} {_.get(a, 'area_3')}</p>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="row mt-50">
            <div className="col-12">
                <h2>{t('chooseShippingAddress')}</h2>
            </div>

            {renderAddresses()}
        </div>
    )
}

export default CartAddress;