import Api from '../../../services/api';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { isLoading, displayErrors } from '../../../application/actions/application.actions';
import { setProducts } from '../../../products/actions/products.actions';


const NAMESPACE = 'ADMIN';

export const SET_PRICINGS = `SET_PRICINGS_${NAMESPACE}`;
export const CLEAR_PRICING = `CLEAR_PRICING_${NAMESPACE}`;
export const SET_PRICING = `SET_PRICING_${NAMESPACE}`;

export const DECREASE_OFFSET = `DECREASE_OFFSET_${NAMESPACE}`;
export const INCREASE_OFFSET = `INCREASE_OFFSET_${NAMESPACE}`;
export const RESET_OFFSET = `RESET_OFFSET_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;
export const SET_PRICING_ERRORS = `SET_PRICING_ERRORS_${NAMESPACE}`;


export const createPricing = (data) => (dispatch, getState) => {
    dispatch(isLoading(true));
    const history = getState().app.history;

    Api.post(`discount/discount`, data).then(res => {
        if (res.success) {
            toast.info('Cennik został utworzony');
            history.push('/admin/pricings');
            window.location.reload();

        } else {
            if (res.errors) {
                displayErrors(res.errors);
                dispatch(setPricingErrors(res.errors))
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const editPricing = (id, data) => (dispatch, getState) => {
    dispatch(isLoading(true));
    const history = getState().app.history;

    Api.put(`discount/discount/${id}`, data).then(res => {
        if (res.success) {
            toast.info('Cennik został zaktualizowany');
            history.push('/admin/pricings');
            window.location.reload();
        } else {
            if (res.errors) {
                displayErrors(res.errors);
                dispatch(setPricingErrors(res.errors))
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const deletePricing = (id) => (dispatch) => {
    dispatch(isLoading(true));

    Api.delete(`discount/discount/${id}`).then(res => {
        if (res.success) {
            toast.info('Cennik został usunięty');
            dispatch(getPricings());
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const getPricings = () => (dispatch, getState) => {
    dispatch(isLoading(true));
    const allParams = { ...getState().admin.params };
    const params = {
        offset: allParams.offset
    }
    Api.get(`discount/discount`, params).then(res => {
        if (res.success) {
            dispatch(setPricings(res.documents, res.total));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const setPricings = (pricings, pricingsTotal) => dispatch => {
    dispatch({
        type: SET_PRICINGS,
        pricings,
        pricingsTotal
    })
}

export const clearPricing = () => dispatch => {
    dispatch({
        type: CLEAR_PRICING
    })
}

export const getPricing = (id) => (dispatch) => {
    dispatch(isLoading(true));

    Api.get(`discount/discount/${id}`).then(res => {
        if (res.success) {
            dispatch(setPricing(res.document));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const setPricing = (pricing) => dispatch => {
    dispatch({
        type: SET_PRICING,
        pricing
    })
}

export const pricingsNextPage = () => (dispatch) => {
    dispatch({
        type: INCREASE_OFFSET
    })
    dispatch(getPricings())
}

export const pricingsPrevPage = () => (dispatch) => {
    dispatch({
        type: DECREASE_OFFSET
    })
    dispatch(getPricings())
}

export const resetOffset = () => dispatch => {
    dispatch({
        type: RESET_OFFSET
    })
}

export const goToPricingsPage = (page) => (dispatch, getState) => {
    const limit = getState().admin.params.limit;
    const newOffset = (page - 1) * limit;

    dispatch({
        type: SET_OFFSET,
        newOffset
    })

    dispatch(getPricings())
}

export const getProductsPricing = (query) => dispatch => {
    dispatch(isLoading(true));
    // const params = { ...getState().products.params };
    let params = {
        limit: 15
    };

    if (query && query !== '') {
        params[`name.pl[orin]`] = query;
        params[`name.en[orin]`] = query;
    }

    Api.get('product/variant', params).then(res => {
        if (res.success) {
            dispatch(setProducts(res.documents, res.total));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const setPricingErrors = (errors) => dispatch => {
    dispatch({
        type: SET_PRICING_ERRORS,
        errors
    })
}