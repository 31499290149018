import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import './search.css';


const Search = (props) => {
    const { onChange } = props;
    const { t } = useTranslation();
    
    const debounced = _.debounce((value) => onChange(value), 500);

    const handleChange = (value) => {
        debounced(value);
    }

    return (
        <div className="search">
            <input type="search" className="search__input" placeholder={`${t('searchAction')}`} onChange={(e) => handleChange(e.target.value)} />
        </div>
    )
}

export default Search;