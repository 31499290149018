import Api from '../../services/api';
import { isLoading, displayErrors } from '../../application/actions/application.actions';

const NAMESPACE = "ORDERS";
export const SET_ORDERS = `SET_ORDERS_${NAMESPACE}`;
export const SET_ORDERS_QUERY = `SET_ORDERS_QUERY_${NAMESPACE}`;
export const SET_ORDERS_FILTER = `SET_ORDERS_FILTER_${NAMESPACE}`;
export const CLEAR_ORDERS = `CLEAR_ORDERS_${NAMESPACE}`;
export const DECREASE_OFFSET = `DECREASE_OFFSET_${NAMESPACE}`;
export const INCREASE_OFFSET = `INCREASE_OFFSET_${NAMESPACE}`;
export const RESET_OFFSET = `RESET_OFFSET_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;

export const getOrders = () => (dispatch, getState) => {
    dispatch(isLoading(true));

    const params = { ...getState().orders.params };

    if (params && params.query !== '') {
        params[`_id[orin]`] = params.query;
        params[`customer.name[orin]`] = params.query;
        params[`orderNumber[orin]`] = params.query;
    }
    if (params && params.filter && params.filter.value !== null) {
        params[`status`] = params.filter.value;
    }

    Api.get(`order/order`, params).then(res => {
        if (res.success) {
            dispatch(setOrders(res.documents, res.total))
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const setOrders = (orders, total) => dispatch => {
    dispatch({
        type: SET_ORDERS,
        orders,
        total
    })
}

export const setOrdersQuery = (query) => dispatch => {
    dispatch({
        type: SET_ORDERS_QUERY,
        query
    })
    dispatch(resetOffset());
    dispatch(getOrders());
}

export const setOrdersFilter = (filter) => dispatch => {
    dispatch({
        type: SET_ORDERS_FILTER,
        filter
    })
    dispatch(resetOffset());
    dispatch(getOrders());
}

export const ordersNextPage = () => (dispatch) => {
    dispatch({
        type: INCREASE_OFFSET
    })
    dispatch(getOrders())
}

export const ordersPrevPage = () => (dispatch) => {
    dispatch({
        type: DECREASE_OFFSET
    })
    dispatch(getOrders())
}

export const resetOffset = () => dispatch => {
    dispatch({
        type: RESET_OFFSET
    })
}

export const goToOrdersPage = (page) => (dispatch, getState) => {
    const limit = getState().sellerOrders.params.limit;
    const newOffset = (page - 1) * limit;

    dispatch({
        type: SET_OFFSET,
        newOffset
    })

    dispatch(getOrders())
}

export const clearOrders = () => dispatch => {
    dispatch({
        type: CLEAR_ORDERS
    })
}
