import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import './productStock.css';

const ProductStock = ({ stockSettings, amount }) => {
    const { t } = useTranslation();

    const stockLabel = () => {
        if (!stockSettings) return;

        switch (true) {
            case amount === 0:
                return t(_.get(stockSettings, 'values[0].label'));
            case (amount >= _.get(stockSettings, 'values[1].from') && amount <= _.get(stockSettings, 'values[1].to')):
                return t(_.get(stockSettings, 'values[1].label'))
            case (amount >= _.get(stockSettings, 'values[2].from') && amount <= _.get(stockSettings, 'values[2].to')):
                return t(_.get(stockSettings, 'values[2].label'))
            case (amount >= _.get(stockSettings, 'values[3].from') && amount <= _.get(stockSettings, 'values[3].to')):
                return t(_.get(stockSettings, 'values[3].label'))
            default:
                return ''
        }
    }

    const stockDots = () => {
        if (!stockSettings) return;

        switch (true) {
            case amount === 0:
                return (
                    <React.Fragment>
                        <span className="stock__dot"></span>
                        <span className="stock__dot"></span>
                        <span className="stock__dot"></span>
                    </React.Fragment>
                )
            case (amount >= _.get(stockSettings, 'values[1].from') && amount <= _.get(stockSettings, 'values[1].to')):
                return (
                    <React.Fragment>
                        <span className="stock__dot stock__full"></span>
                        <span className="stock__dot"></span>
                        <span className="stock__dot"></span>
                    </React.Fragment>
                )
            case (amount >= _.get(stockSettings, 'values[2].from') && amount <= _.get(stockSettings, 'values[2].to')):
                return (
                    <React.Fragment>
                        <span className="stock__dot stock__full"></span>
                        <span className="stock__dot stock__full"></span>
                        <span className="stock__dot"></span>
                    </React.Fragment>
                )
            case (amount >= _.get(stockSettings, 'values[3].from') && amount <= _.get(stockSettings, 'values[3].to')):
                return (
                    <React.Fragment>
                        <span className="stock__dot stock__full"></span>
                        <span className="stock__dot stock__full"></span>
                        <span className="stock__dot stock__full"></span>
                    </React.Fragment>
                )
        }
    }

    return (
        <div className="stock">
            <p className="stock__label mb-2">{t('stock')}</p>
            <div className="stock__level">
                <p className="mr-4">{stockLabel()}</p>

                {stockDots()}

            </div>
        </div>
    )
}

export default ProductStock;