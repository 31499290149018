import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './tableList.css';

import Edytuj from '../../../assets/icons/edytuj.svg';
// import CheckWhite from '../../../assets/icons/check-white.svg';
import ZamknijWhite from '../../../assets/icons/zamknij-white.svg';

const TableList = (props) => {
    const { values, onActivate, onDelete, simpleList, editRoute } = props;
    const { t } = useTranslation();

    const renderRows = () => {
        if (values && simpleList) {
            return values.map((v, i) => {
                return (
                    <tr key={i}>
                        <td>
                            <Link to={`/${editRoute}/${v._id}`}>{v.name}</Link>
                        </td>
                    </tr>
                )
            })
        }

        if (values) {
            return values.map((v, i) => {
                return (
                    <tr key={i}>
                        <td>{`${i + 1}. ${v.name}`}</td>
                        <td width="100px">
                            <div className="client__action client__action--edit">
                                <Link to={`/${editRoute}/${v._id}`}><img src={Edytuj} alt="" /></Link>
                            </div>
                        </td>
                        {/* {
                            (!v.active) ?
                                <td width="100px">
                                    <div className="client__action client__action--activate">
                                        <img src={CheckWhite} alt="" onClick={() => onActivate(v._id)} />
                                    </div>
                                </td>
                                : 
                                <td width="100px">
                                </td>
                        } */}
                        <td width="100px">
                            <div className="client__action client__action--delete" onClick={() => onDelete(v._id)} >
                                <img src={ZamknijWhite} alt="" />
                            </div>
                        </td>
                    </tr>
                )
            })
        }
    }

    return (
        <div className="table-responsive">
            <table className="table table-striped admin-clients__table">
                {
                    (!simpleList) ?
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">{t('editAction')}</th>
                                {/* <th scope="col">{t('activateAction')}</th> */}
                                <th scope="col">{t('deleteAction')}</th>
                            </tr>
                        </thead>
                        : null
                }
                <tbody>
                    {renderRows()}
                </tbody>
            </table>
        </div>
    )
}

export default TableList;