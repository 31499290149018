import Api from '../../services/api';
import { toast } from 'react-toastify';
import { isLoading, displayErrors } from '../../application/actions/application.actions';

import { clearCartInLocalStorage, clearCart } from '../../cart/actions/cart.actions';

const NAMESPACE = "ORDER";
export const SET_ORDER = `SET_ORDER_${NAMESPACE}`;
export const CLEAR_ORDER = `CLEAR_ORDER_${NAMESPACE}`;

export const saveOrder = (form) => (dispatch, getState) => {
    const history = getState().app.history;
    dispatch(isLoading(true));

    Api.post(`order/order`, form).then(res => {
        if (res.success) {
            //wyczysc koszyk
            clearCartInLocalStorage();
            dispatch(clearCart());
            //przejscie na liste zamowien
            history.push('/orders');
            //powiadomienie
            toast.info('Zamówienie zostało złożone i czeka na akceptację');
            localStorage.removeItem('selectedAddressIndex');
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const getOrder = (id) => dispatch => {
    dispatch(isLoading(true));

    Api.get(`order/order/${id}`).then(res => {
        if (res.success) {
            dispatch(setOrder(res.document));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

const setOrder = (order) => dispatch => {
    dispatch({
        type: SET_ORDER,
        order
    })
}

export const clearOrder = () => dispatch => {
    dispatch({
        type: CLEAR_ORDER
    })
}