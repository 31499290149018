import React from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import TextInput from '../formComponents/TextInput';

const ChangePassword = (props) => {
    const { changePassword } = props;
    const { t } = useTranslation();
    const params = useParams();

    return (
        <section id="login" className="login content mt-50">
            <div className="container">
                <div className="d-flex row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="login__form">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="text-center">{t('changePassword')}</h1>
                                </div>
                                <Formik
                                    initialValues={{ password: '', passwordConfirm: '' }}
                                    validationSchema={Yup.object({
                                        password: Yup.string()
                                            .required(`${t('passwordError')}`),
                                        passwordConfirm: Yup.string()
                                            .oneOf([Yup.ref('password'), null], `${t('matchingPasswordError')}`)
                                            .required(`${t('passwordError')}`)
                                    })}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onSubmit={(values, { resetForm, setErrors }) => {
                                        if (params && params.id) {
                                            changePassword(values.password, values.passwordConfirm, params.id).then(res => {
                                                if (res.success) {
                                                    resetForm();
                                                } else {
                                                    if (res.errors) {
                                                        setErrors(res.errors);
                                                    }
                                                }
                                            })
                                        }
                                    }}
                                >
                                    {formik => (
                                        <form className="row" onSubmit={formik.handleSubmit}>
                                            <TextInput
                                                id="password"
                                                label={t('password')}
                                                type="password"
                                                onChange={formik.setFieldValue}
                                                value={formik.values.password}
                                                errors={formik.errors.password}
                                            />
                                            <TextInput
                                                id="passwordConfirm"
                                                label={t('repeatPassword')}
                                                type="password"
                                                onChange={formik.setFieldValue}
                                                value={formik.values.passwordConfirm}
                                                errors={formik.errors.passwordConfirm}
                                            />

                                            <div className="col-12 mt-5 text-center">
                                                <button className="btn btn--primary" type="submit">{t('changePasswordAction')}</button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ChangePassword;