import {
    SET_INVOICE, 
    CLEAR_INVOICE
} from '../actions/invoice.actions';

const defaultState = {
    invoice: null
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_INVOICE:
            return {
                ...state,
                invoice: action.invoice
            }
        case CLEAR_INVOICE:
            return defaultState;
        default:
            return state;

    }
}