import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Pagination from '../../../application/components/pagination/Pagination.component';
import {
    deleteDiscountGroup,
    discountGroupsNextPage,
    discountGroupsPrevPage,
    getDiscountGroups,
    goToDiscountGroupsPage,
    resetOffset
} from '../../actions/discountGroup/discountGroup.actions';
import DiscountGroups from '../../components/discountGroup/DiscountGroups.component';

const DiscountGroupsContainer = ({ getDiscountGroups, discountGroups, discountGroupsNextPage, discountGroupsPrevPage, goToDiscountGroupsPage, total, params, resetOffset, deleteDiscountGroup }) => {
    const { t } = useTranslation();

    useEffect(() => {
        getDiscountGroups();
    }, [getDiscountGroups])
    useEffect(() => {
        return () => {
            resetOffset();
        }
    }, [resetOffset])

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h1>{t('discountGroups')}</h1>
                </div>
            </div>
            <div className="col-12 text-center mt-5">
                <Link to='/admin/discountGroup' id="" className="btn btn--primary">{t('addDiscountGroup')}</Link >
            </div>
            {discountGroups && discountGroups.length > 0 &&
                <div className="col-lg-12 mt-5">
                    <Pagination
                        onPrevPageClick={discountGroupsPrevPage}
                        onNextPageClick={discountGroupsNextPage}
                        onPageClick={goToDiscountGroupsPage}
                        params={params}
                        total={total}
                    />
                </div>
            }
            <DiscountGroups
                discountGroups={discountGroups}
                deleteDiscountGroup={deleteDiscountGroup}
            />
            {discountGroups && discountGroups.length > 0 &&
                <div className="col-lg-12 mt-5">
                    <Pagination
                        onPrevPageClick={discountGroupsPrevPage}
                        onNextPageClick={discountGroupsNextPage}
                        onPageClick={goToDiscountGroupsPage}
                        params={params}
                        total={total}
                    />
                </div>
            }
        </div>
    )
}

const mapStateToProps = (store) => ({
    discountGroups: store.admin.discountGroups,
    total: store.admin.discountGroupsTotal,
    params: store.admin.params
});

const mapDispatchToProps = (dispatch) => {
    return {
        getDiscountGroups: () => dispatch(getDiscountGroups()),
        discountGroupsNextPage: () => dispatch(discountGroupsNextPage()),
        discountGroupsPrevPage: () => dispatch(discountGroupsPrevPage()),
        resetOffset: () => dispatch(resetOffset()),
        goToDiscountGroupsPage: (page) => dispatch(goToDiscountGroupsPage(page)),
        deleteDiscountGroup: (id) => dispatch(deleteDiscountGroup(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountGroupsContainer);