import React from 'react';
import { connect } from 'react-redux'

import LostPassword from '../../components/lostPassword/lostPassword.component';

import { resetPassword } from '../../actions/login.action';

const LostPasswordContainer = (props) => {
    return (
        <LostPassword {...props} />
    )
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (email) => dispatch(resetPassword(email))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LostPasswordContainer);