import React from 'react';
import { connect } from 'react-redux'

import Login from '../../components/login/Login.component';

import { login } from '../../actions/login.action';

const LoginContainer = (props) => {
    return (
        <Login {...props} />
    )
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => {
    return {
        login: (email, password) => dispatch(login(email, password))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);