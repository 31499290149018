import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const UnpaidInvoices = ({ invoices }) => {
    const { t } = useTranslation();

    const renderInvoices = () => {
        if (!invoices) return;

        return invoices.map((inv, i) => {
            if (inv.status === 2) {
                return (
                    <tr key={_.get(inv, '_id')}>
                        <td>
                            <Link to={`/invoice/${_.get(inv, '_id')}`}>{i + 1}. {t('invoice')} {_.get(inv, 'number')} / -{parseInt(_.get(inv, 'leftToPay')).toFixed(2)} zł</Link>
                        </td>
                    </tr>
                )
            }
            return null;
        })
    }

    return (
        <div className="col-12 mt-5">
            <div className="table-responsive">
                <table className="table table-striped">
                    <tbody>
                        {renderInvoices()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default UnpaidInvoices;