import Api from '../../../services/api';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { isLoading, displayErrors } from '../../../application/actions/application.actions';

import { getApplicationSettings } from '../../../application/actions/application.actions';

export const updateStocksSettings = (id, values) => (dispatch) => {
    dispatch(isLoading(true));

    Api.put(`application/settings/${id}`, { values: values }).then(res => {
        if (res.success) {
            toast.info('Ustawienia zostały zaktualizowane')
            dispatch(getApplicationSettings());
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}
