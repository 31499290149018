import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import './invoices.css';

import DatePicker from '../../application/components/datePicker/DatePicker.component';

import Invoices from '../components/Invoices.component';
import UnpaidInvoices from '../components/UnpaidInvoices.component';
import Select from '../../application/components/formComponents/Select';
import Search from '../../application/components/search/Search';
// import Orders from '../components/Orders.component';
// import Pagination from '../../application/components/pagination/Pagination.component';

import { getInvoices, setInvoicesQuery, setInvoicesFilter, clearInvoices, invoicesNextPage, invoicesPrevPage, goToInvoicesPage, filterByDate } from '../actions/invoices.actions'


const OrdersContainer = ({ getInvoices, invoices, setInvoicesQuery, setInvoicesFilter, clearInvoices, params, total, invoicesNextPage, invoicesPrevPage, goToInvoicesPage, filterByDate }) => {
    const { t } = useTranslation();

    useEffect(() => {
        getInvoices()
    }, [getInvoices])

    useEffect(() => {
        return () => {
            clearInvoices();
        }
    }, [clearInvoices])

    const [filter, setFilter] = useState('')

    const handleInvoicesSearch = (query) => {
        setInvoicesQuery(query)
    }

    const handleFilterChange = (filter) => {
        setFilter(filter);
        setInvoicesFilter(filter)
    }

    return (
        <section id="invoices" className="invoices content">
            <div className="container">
                <div className="row mt-5">
                    <div className="col-12">
                        <p className="h1">{t('unclearedInvoices')}</p>
                    </div>

                    <UnpaidInvoices
                        invoices={invoices}
                    />

                    <div className="col-12 mt-5">
                        <h1>{t('allInvoices')}</h1>
                    </div>


                    <div className="col-12 mt-5">
                        <Search
                            onChange={(query) => handleInvoicesSearch(query)}
                        />
                    </div>

                    <div className="row">
                        <div className="col-lg-5 mt-5">
                            <Select
                                id="filters"
                                label={t('filters')}
                                placeholder={t('filters')}
                                options={[
                                    { name: 'Wszystkie', value: null },
                                    { name: 'Opłacone', value: 1 },
                                    { name: 'Nieopłacone', value: 2 },
                                ]}
                                noFormik={true}
                                value={filter.name}
                                onChange={(filter) => handleFilterChange(filter)}
                            />
                        </div>
                        <div className="col-lg-3 mt-5">
                            <label>{t('dateFrom')}:</label>
                            <DatePicker
                                date={_.get(params, 'createdAt[gte]')}
                                setDate={(date) => filterByDate('gte', date)}
                            />
                        </div>
                        <div className="col-lg-3 mt-5">
                            <label>{t('dateTo')}:</label>
                            <DatePicker
                                date={_.get(params, 'createdAt[lte]')}
                                setDate={(date) => filterByDate('lte', date)}
                            />
                        </div>

                    </div>

                    {
                        (invoices && invoices.length > 0) ?
                            <Invoices
                                invoices={invoices}
                            />
                            : <div className="col-lg-12 mt-5">{t('noInvoices')}</div>

                    }



                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (store) => ({
    invoices: store.invoices.invoices,
    params: store.invoices.params,
    total: store.invoices.invoicesTotal,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getInvoices: () => dispatch(getInvoices()),
        setInvoicesQuery: (querty) => dispatch(setInvoicesQuery(querty)),
        setInvoicesFilter: (filter) => dispatch(setInvoicesFilter(filter)),
        clearInvoices: () => dispatch(clearInvoices()),
        invoicesNextPage: () => dispatch(invoicesNextPage()),
        invoicesPrevPage: () => dispatch(invoicesPrevPage()),
        goToInvoicesPage: (page) => dispatch(goToInvoicesPage(page)),
        filterByDate: (type, date) => dispatch(filterByDate(type, date)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersContainer);