import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './invoice.css';

import { getInvoice, clearInvoice } from '../actions/invoice.actions';

import InvoiceStatus from '../components/InvoiceStatus.components';
import OrderProducts from '../../order/components/OrderProducts.component';

const InvoiceContainer = ({ getInvoice, invoice, clearInvoice, currency }) => {
    const params = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        if (params && params.id) {
            getInvoice(params.id);
        }

        return () => {
            clearInvoice();
        }
    }, [getInvoice, clearInvoice])



    if (!invoice) return null;

    return (
        <section id="invoice" className="invoice content">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>{t('invoice')} {_.get(invoice, '_id')}</h1>
                    </div>

                    <InvoiceStatus
                        status={_.get(invoice, 'status')}
                    />

                    <div className="col-12">
                        <OrderProducts
                            products={_.get(invoice, 'products')}
                        />
                    </div>

                    <div className="col-12 mt-4">
                        <div className="invoice__total">
                            <h3>{t('total')}</h3>
                            <p className="total__price total__price--netto">{parseInt(_.get(invoice, 'price', 0)).toFixed(2)} {currency} netto</p>
                            <p className="total__price total__price--brutto">{parseInt(_.get(invoice, 'priceBrutto', 0)).toFixed(2)} {currency} brutto</p>
                        </div>
                    </div>
                    {
                        <div className="col-12 mt-4">
                            <div className="invoice__total">
                                <h3>{t('leftToPay')}</h3>
                                <p className="total__price total__price--netto">{_.get(invoice, 'leftToPay').toFixed(2)} {currency}</p>
                            </div>
                        </div>
                    }
                    <div className="col-12 mt-5">
                        <div className="invoice__data">
                            <h3>{t('invoiceData')}</h3>
                            {_.get(invoice, 'customer.name')}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (store) => ({
    invoice: store.invoice.invoice,
    currency: _.get(store, 'app.user.currency')
});

const mapDispatchToProps = (dispatch) => {
    return {
        getInvoice: (id) => dispatch(getInvoice(id)),
        clearInvoice: () => dispatch(clearInvoice()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceContainer);