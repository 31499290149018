import React from 'react';
import _ from 'lodash';

import Usun from '../../assets/icons/usun.svg';
import AutoComplete2 from '../../application/components/formComponents/AutoComplete2';
import DatePicker from '../../application/components/datePicker/DatePicker.component';
import { useTranslation } from 'react-i18next';

const SellerSplitOrder = ({ products, getProducts, handleProductAdd, orders, handleSelectProduct, handleAddSplitOrder, handleDeleteSplitOrder, handleChangeSplitOrderDate, handleAddDescriptionToSplitOrders, handleSendSplitOrder, applyOrderDiscount }) => {
    const { t } = useTranslation();

    const renderPrice = (p, priceFieldName) => {
        let discountValue = parseInt(_.get(p, 'discount', 0)) / 100;
        let price = parseFloat(_.get(p, priceFieldName, 0));
        if (isNaN(discountValue) || discountValue <= 0) {
            return price.toFixed(2);
        } else if (discountValue >= 1) {
            return 0;
        }
        return (price - price * discountValue).toFixed(2);
    }

    const renderProducts = (products, orderIndex) => {
        if (!products) return;

        return products.map((p, i) => {
            return (
                <tr key={i}>
                    <td>
                        <div className="order__name d-flex align-items-center justify-content-center">
                            <input
                                type="checkbox"
                                checked={(p.checked) ? true : false}
                                onChange={(e) => handleSelectProduct(i, orderIndex, { checked: e.target.checked })}
                            />
                        </div>
                    </td>
                    <td>
                        <div className="order__name d-flex align-items-center justify-content-center">
                            <span className="ml-3">{i + 1}. {_.get(p, 'name.pl')}</span>
                        </div>
                    </td>
                    <td>
                        <div className="order__netto">
                            <p>{renderPrice(p, "price")} {_.get(p, 'currency')} netto</p>
                        </div>
                    </td>
                    <td>
                        <div className="order__brutto">
                            <p>{renderPrice(p, "priceBrutto")} {_.get(p, 'currency')} brutto</p>
                        </div>
                    </td>
                    <td>
                        <div className="order__quantity">
                            <input
                                type="number"
                                style={{ border: '1px solid gray' }}
                                value={_.get(p, 'discount')}
                                onChange={(e) => handleSelectProduct(i, orderIndex, { discount: e.target.value })}
                            />
                        </div>
                    </td>
                    <td>
                        <div className="order__quantity">
                            <input
                                type="number"
                                min={1}
                                style={{ border: '1px solid gray' }}
                                value={_.get(p, 'amount')}
                                onChange={(e) => handleSelectProduct(i, orderIndex, { amount: e.target.value })}
                            />
                        </div>
                    </td>

                    {/*  */}
                    <td>
                        <div className="order__stock">
                            <p className="text-center">{_.get(p, 'warehouseAmount')}</p>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    const renderOrders = () => {
        return orders.map((o, i) => {
            if (!o.orderDate) {
                o.orderDate = new Date();
            } else {
                o.orderDate = new Date(o.orderDate);
            }
            return (
                <React.Fragment key={i}>
                    <hr className="break__element mt-5"></hr>
                    <div className="row mt-4">
                        <div className="col-12">
                            <h4>{t('order')} {i + 1}</h4>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 mb-5">
                            <div className="rounded-box p-5 d-flex justify-content-start align-items-center">
                                <div className="order__quantity mr-5">
                                    <h4>Data realizacji</h4>
                                    <DatePicker
                                        date={o.orderDate}
                                        setDate={(date) => handleChangeSplitOrderDate(i, date)}
                                    />
                                </div>

                                <div className="order__quantity pt-4">
                                    <h4>Rabat całkowity (%)</h4>
                                    <input
                                        type="number"
                                        min={1}
                                        style={{ border: '1px solid #dedede' }}
                                        value={_.get({}, 'amount')}
                                        onChange={(e) => applyOrderDiscount(i, e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="table-responsive rounded-box p-4">
                                <table className="table order__table--more">
                                    <thead>
                                        <tr>
                                            <th scope="col">{t('addAction')}</th>
                                            <th scope="col">{t('title')}</th>
                                            <th scope="col">{t('price')} netto</th>
                                            <th scope="col">{t('price')} brutto</th>
                                            <th scope="col">{t('discount')} (%)</th>
                                            <th scope="col" className="text-center">{t('amount')}</th>
                                            <th scope="col" className="text-center">{t('stock')}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {renderProducts(o.products, i)}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12">
                                    <AutoComplete2
                                        label={t('products')}
                                        options={(products) ? products : null}
                                        onChange={(product) => handleProductAdd(product, i)}
                                        getFunction={(value) => getProducts(value)}
                                        multiselect={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-auto col-lg md-lg-0">
                            <div className="order__delete cursor-pointer rounded-box p-4 h-100 d-flex align-items-center justify-content-center flex-column"
                                onClick={() => handleDeleteSplitOrder(i)}
                            >
                                <img src={Usun} alt="" />
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            )
        })
    }

    return (
        <div id="orderSplit" className="order__split collapse mt-50">

            {renderOrders()}

            <div className="row mt-5">
                <div className="col-auto">
                    <button className="btn btn--primary"
                        onClick={() => handleAddSplitOrder()}
                    >Dodaj kolejne zamówienie</button>
                </div>
            </div>

            <div className="row mt-50">
                <div className="col-12">
                    <h4>{t('orderDescription')}</h4>
                    <textarea className="mt-4" onChange={(e) => handleAddDescriptionToSplitOrders(e.target.value)}></textarea>
                </div>
            </div>

            <div className="row mt-50 justify-content-center">
                <div className="col-auto">
                    <button className="btn btn--primary"
                        onClick={() => handleSendSplitOrder()}
                    >Wyślij wszystkie zamówienia</button>
                </div>
            </div>
        </div>
    )
}

export default SellerSplitOrder;