// import {
//     SET_PRODUCT,
//     CLEAR_PRODUCT
// } from '../actions/product.actions';

const defaultState = {
    pricings: null
}

export default (state = defaultState, action) => {
    switch (action.type) {
        // case SET_PRODUCT:
        //     return {
        //         ...state,
        //         product: action.product
        //     }
        default:
            return state;
    }
}