import React from 'react';

import Logo from '../../../assets/icons/logo.svg';

const HeaderLogo = ({ history }) => {
    return (
        <div className="col-auto">
            <div className="header__logo" role="button"
                onClick={() => history.push('/')}
            >
                <img src={Logo} alt="logob2b" width={"70%"}/>
            </div>
        </div>
    )
}

export default HeaderLogo;