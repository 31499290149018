import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableList from '../../../application/components/tableList/TableList';
import Search from '../../../application/components/search/Search';
import ConfirmationModal from '../../../application/components/modal/ConfirmationModal.component';

const AdminClients = (props) => {
    const { getClients, clients, changeIsActiveClient, deleteClientAccount } = props;
    const { t } = useTranslation();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    useEffect(() => {
        getClients();
    }, [getClients])

    const handleClientSearch = (query) => {
        getClients(query);
    }

    const handleClientActivate = (id) => {
        changeIsActiveClient(true, id)
    }
    const handleClientDelete = (id) => {
        setIdToDelete(id);
        setShowConfirmationModal(true);
    }

    const handleConfirmDeleteModal = () => {
        deleteClientAccount(idToDelete);
        setShowConfirmationModal(false);
    }

    const handleCancelDeleteModal = () => {
        setIdToDelete(null);
        setShowConfirmationModal(false);
    }


    return (
        <div className="col-12">
            {
                showConfirmationModal &&
                <ConfirmationModal
                    text={'Usunąć klienta?'}
                    onConfirm={handleConfirmDeleteModal}
                    onCancel={handleCancelDeleteModal}
                />
            }

            <div className="col">
                <div className="row">

                    <div className="col-12 mt-5">
                        <h2 className="h1">{t('clientList')}</h2>
                    </div>
                    <div className="col-12 mt-4">
                        <Search
                            onChange={(query) => handleClientSearch(query)}
                        />
                    </div>
                    {
                        <div className="col-12 mt-5">
                            <TableList
                                values={clients}
                                onActivate={handleClientActivate}
                                onDelete={handleClientDelete}
                                editRoute={'admin/clients'}
                            />
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default AdminClients;