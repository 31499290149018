import {
    SET_PRODUCT,
    CLEAR_PRODUCT,
    SET_VARIANT
} from '../actions/product.actions';

const defaultState = {
    product: null,
    variant: null
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_PRODUCT:
            return {
                ...state,
                product: action.product
            }
        case SET_VARIANT:
            return {
                ...state,
                variant: action.variant
            }
        case CLEAR_PRODUCT:
            return {
                ...state,
                product: null
            }
        default:
            return state;
    }
}