import Api from '../../services/api';
import { isLoading, displayErrors } from '../../application/actions/application.actions';

const NAMESPACE = "INVOICE";
export const SET_INVOICE = `SET_INVOICE_${NAMESPACE}`;
export const CLEAR_INVOICE = `CLEAR_INVOICE_${NAMESPACE}`;

export const getInvoice = (id) => (dispatch) => {
    dispatch(isLoading(true));

    Api.get(`invoice/invoice/${id}`).then(res => {
        if (res.success) {
            dispatch(setInvoice(res.document))
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const setInvoice = (invoice) => dispatch => {
    dispatch({
        type: SET_INVOICE,
        invoice
    })
}

export const clearInvoice = () => dispatch => {
    dispatch({
        type: CLEAR_INVOICE
    })
}

