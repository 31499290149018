import React, { useEffect } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getPricings, pricingsNextPage, pricingsPrevPage, goToPricingsPage, resetOffset, deletePricing } from '../../actions/pricing/pricing.actions';

import Pricings from '../../components/pricing/Pricings.component';

import Pagination from '../../../application/components/pagination/Pagination.component';


const PricingsContainer = ({ getPricings, pricings, pricingsNextPage, pricingsPrevPage, goToPricingsPage, total, params, resetOffset, deletePricing }) => {
    const { t } = useTranslation();

    useEffect(() => {
        getPricings();
    }, [getPricings])
    useEffect(() => {
        return () => {
            resetOffset();
        }
    }, [resetOffset])


    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h1>{t('pricings')}</h1>
                </div>
            </div>
            <div className="col-12 text-center mt-5">
                <Link to='/admin/pricing' id="" className="btn btn--primary">{t('addPricing')}</Link >
            </div>
            {pricings && pricings.length > 0 &&
                <div className="col-lg-12 mt-5">
                    <Pagination
                        onPrevPageClick={pricingsPrevPage}
                        onNextPageClick={pricingsNextPage}
                        onPageClick={goToPricingsPage}
                        params={params}
                        total={total}
                    />
                </div>
            }
            <Pricings
                pricings={pricings}
                deletePricing={deletePricing}
            />
            {pricings && pricings.length > 0 &&
                <div className="col-lg-12 mt-5">
                    <Pagination
                        onPrevPageClick={pricingsPrevPage}
                        onNextPageClick={pricingsNextPage}
                        onPageClick={goToPricingsPage}
                        params={params}
                        total={total}
                    />
                </div>
            }
        </div>
    )
}

const mapStateToProps = (store) => ({
    pricings: store.admin.pricings,
    total: store.admin.pricingsTotal,
    params: store.admin.params
});

const mapDispatchToProps = (dispatch) => {
    return {
        getPricings: () => dispatch(getPricings()),
        pricingsNextPage: () => dispatch(pricingsNextPage()),
        pricingsPrevPage: () => dispatch(pricingsPrevPage()),
        resetOffset: () => dispatch(resetOffset()),
        goToPricingsPage: (page) => dispatch(goToPricingsPage(page)),
        deletePricing: (id) => dispatch(deletePricing(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingsContainer);