import React from 'react';
import { connect } from 'react-redux'
import { getClients } from '../actions/clients.actions';
import Clients from '../components/Clients.component';

const ClientContainer = (props) => {
    return (
        <div>
            <Clients
                clients={props.clients}
                getClients={props.getClients}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    clients: state.clients.clients
});

const mapDispatchToProps = (dispatch) => {
    return {
        getClients: (searchQuery) => dispatch(getClients(searchQuery))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientContainer);