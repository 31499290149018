import React, { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { connect } from 'react-redux'
import _ from 'lodash';

import './myAccount.css'

import TextInput from '../../application/components/formComponents/TextInput';
import AccountDetails from '../components/AccountDetails.component';
import AccountAddresses from '../components/AccountAddresses.component';

import { updateProfile } from '../actions/myAccount.actions'

const MyAccountContainer = ({ user, updateProfile }) => {
    const { t } = useTranslation();

    const [showAddressForm, setShowAddressForm] = useState(false);
    const [addressIndexToEdit, setAddressIndexToEdit] = useState(-1);

    const handleAddressEdit = (i) => {
        setAddressIndexToEdit(i);
        setShowAddressForm(true);
    }

    const handleAddressAdd = () => {
        setAddressIndexToEdit(-1);
        setShowAddressForm(true);
    }

    const handleSubmitAddress = (addresses) => {
        updateProfile(addresses);
        setShowAddressForm(false);
    }

    return (
        <section id="account" className="account content">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>{t('profile')}</h1>
                    </div>

                    <AccountDetails
                        name={_.get(user, 'name')}
                        email={_.get(user, 'email')}
                        phone={_.get(user, 'phone')}
                        nip={_.get(user, 'NIP')}
                    />

                    <AccountAddresses
                        addresses={_.get(user, 'addresses')}
                        handleAddressEdit={handleAddressEdit}
                    />


                    <div className="col-12 mt-5">
                        <button className="btn btn--primary" type="button"
                            // data-toggle="collapse" data-target="#addAddress" aria-expanded="false" aria-controls="addAddress"
                            onClick={() => handleAddressAdd()}
                        >{t('addAdditionalAddress')}</button>
                    </div>

                    {showAddressForm &&
                        <Formik
                            initialValues={{
                                street: (addressIndexToEdit > -1) ? _.get(user, `addresses[${addressIndexToEdit}].street`) : '',
                                streetNumber: (addressIndexToEdit > -1) ? _.get(user, `addresses[${addressIndexToEdit}].streetNumber`) : '',
                                postCode: (addressIndexToEdit > -1) ? _.get(user, `addresses[${addressIndexToEdit}].postCode`) : '',
                                locality: (addressIndexToEdit > -1) ? _.get(user, `addresses[${addressIndexToEdit}].locality`) : '',
                            }}
                            validationSchema={Yup.object({
                                street: Yup.string()
                                    .required(`${t('streetIsRequired')}`),
                                streetNumber: Yup.string()
                                    .required(`${t('streetNumberIsRequired')}`),
                                postCode: Yup.string()
                                    .required(`${t('postalCodeIsRequired')}`),
                                locality: Yup.string()
                                    .required(`${t('localityIsRequired')}`),
                            })}
                            enableReinitialize={true}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values) => {
                                if (user && user.addresses) {
                                    if (addressIndexToEdit > -1) {
                                        user.addresses[addressIndexToEdit].street = values.street;
                                        user.addresses[addressIndexToEdit].streetNumber = values.streetNumber;
                                        user.addresses[addressIndexToEdit].postCode = values.postCode;
                                        user.addresses[addressIndexToEdit].locality = values.locality;
                                    } else {
                                        user.addresses.push(values);
                                    }
                                    handleSubmitAddress(user.addresses);
                                }
                            }}
                        >
                            {formik => (
                                <div className="col-12">
                                    <div className="add__address mt-5" id="addAddress">
                                        <div className="row">
                                            <div className="col-12 mb-4">
                                                <h2>{(addressIndexToEdit > -1) ? t('editAddress') : t('addAddress')}</h2>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <TextInput
                                                        id="street"
                                                        label={t('street')}
                                                        type="text"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.street}
                                                        errors={formik.errors.street}
                                                    />
                                                    <TextInput
                                                        id="streetNumber"
                                                        label={t('streetNumber')}
                                                        type="text"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.streetNumber}
                                                        errors={formik.errors.streetNumber}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <TextInput
                                                        id="postCode"
                                                        label={t('postalCode')}
                                                        type="text"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.postCode}
                                                        errors={formik.errors.postCode}
                                                    />
                                                    <TextInput
                                                        id="locality"
                                                        label={t('locality')}
                                                        type="text"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.locality}
                                                        errors={formik.errors.locality}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-auto mt-5">
                                                <button className="btn btn--primary" type="submit" onClick={formik.handleSubmit}>{(addressIndexToEdit > -1) ? t('editAddress') : t('addAddress')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Formik>
                    }

                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => ({
    user: state.app.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateProfile: (addresses) => dispatch(updateProfile(addresses))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountContainer);