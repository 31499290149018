import Api from '../../services/api';
import { toast } from 'react-toastify';
import { isLoading, displayErrors } from '../../application/actions/application.actions';

export const updateProfile = (addresses) => (dispatch) => {
    dispatch(isLoading(true));

    Api.put(`profile/profile`, { addresses: addresses }).then(res => {
        if (res.success) {
            toast.info('Adresy zostały zaktualizowane');
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

