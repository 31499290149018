import React from 'react';
import { connect } from 'react-redux'

import ChangePassword from '../../components/changePassword/ChangePassword.component';

import { changePassword } from '../../actions/login.action';

const ChangePasswordContainer = (props) => {
    return (
        <ChangePassword
            changePassword={props.changePassword}
        />
    )
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (password, passwordConfirm, token) => dispatch(changePassword(password, passwordConfirm, token))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordContainer);