import Api from '../../services/api';
import { isLoading, displayErrors } from '../../application/actions/application.actions';

const NAMESPACE = "CLIENT";
export const SET_PRODUCT_CATEGORIES_TREE = `SET_PRODUCT_CATEGORIES_TREE_${NAMESPACE}`;
export const SET_PRODUCTS = `SET_PRODUCTS_${NAMESPACE}`;
export const INCREASE_OFFSET = `INCREASE_OFFSET_${NAMESPACE}`;
export const DECREASE_OFFSET = `DECREASE_OFFSET_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;
export const RESET_OFFSET = `RESET_OFFSET_${NAMESPACE}`;
export const CLEAR_PRODUCTS = `CLEAR_PRODUCTS_${NAMESPACE}`;
export const SET_PRODUCTS_QUERY = `SET_PRODUCTS_QUERY_${NAMESPACE}`;
export const SET_PRODUCTS_CATEGORY = `SET_PRODUCTS_CATEGORY_${NAMESPACE}`;
export const RESET_PRODUCTS_CATEGORY = `RESET_PRODUCTS_CATEGORY_${NAMESPACE}`;

export const getCategoriesTree = () => dispatch => {
    dispatch(isLoading(true));

    Api.get('product/getTree').then(res => {
        if (res.success) {
            dispatch(setProductCategoriesTree(res.document));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

const setProductCategoriesTree = (productCategoriesTree) => dispatch => {
    dispatch({
        type: SET_PRODUCT_CATEGORIES_TREE,
        productCategoriesTree
    })
}
const setLowestPrice = (products, showPrices) => {
    products.forEach(p => {
        p.price = variantsLowestPrice(p.variants, showPrices);
    })

    return products;
}

const variantsLowestPrice = (variants, showPrices) => {
    if (variants) {
        let lowestPrice;
        variants.forEach((v, i) => {
            if (typeof showPrices !== "undefined" && !showPrices && v.priceBeforeDiscount) {
                if (i === 0) lowestPrice = v.priceBeforeDiscount;

                if (v.priceBeforeDiscount < lowestPrice) {
                    lowestPrice = v.priceBeforeDiscount;
                }
            } else {
                if (i === 0) lowestPrice = v.price;

                if (v.price < lowestPrice) {
                    lowestPrice = v.price;
                }
            }
        })

        return lowestPrice;
    }

}

export const getProducts = (id, noLimit = false) => (dispatch, getState) => {
    dispatch(isLoading(true));
    const params = { ...getState().products.params };

    if (params && params.query !== '') {
        params[`name.pl[orin]`] = params.query;
        params[`name.en[orin]`] = params.query;
    }
    if (id) {
        params[`categories`] = id;
    }
    if (noLimit) {
        params.limit = 9999;
    }

    Api.get('product/product', params).then(res => {
        if (res.success) {
            const showPrices = getState().app.user.showPrices;

            res.documents = setLowestPrice(res.documents, showPrices);
            dispatch(setProducts(res.documents, res.total));
        } else {
            if (res.errors) {
                displayErrors(res.errors);
            }
        }
        dispatch(isLoading(false));
    }).catch(err => {
        displayErrors(err);
        dispatch(isLoading(false));
    })
}

export const setProductsQuery = (query, id) => dispatch => {
    dispatch({
        type: SET_PRODUCTS_QUERY,
        query
    })
    dispatch(resetOffset());
    dispatch(getProducts(id));
}

export const setProducts = (products, total) => dispatch => {
    dispatch({
        type: SET_PRODUCTS,
        products,
        productsTotal: total
    })
}

export const productsNextPage = () => (dispatch) => {
    dispatch({
        type: INCREASE_OFFSET
    })
    dispatch(getProducts())
}

export const productsPrevPage = () => (dispatch) => {
    dispatch({
        type: DECREASE_OFFSET
    })
    dispatch(getProducts())
}

export const resetOffset = () => dispatch => {
    dispatch({
        type: RESET_OFFSET
    })
}

export const goToProductsPage = (page, id = null) => (dispatch, getState) => {
    const limit = getState().products.params.limit;
    const newOffset = (page - 1) * limit;
    dispatch({
        type: SET_OFFSET,
        newOffset
    })
    dispatch(getProducts(id))
}

export const clearProducts = () => dispatch => {
    dispatch(resetOffset());
    dispatch({
        type: CLEAR_PRODUCTS
    })
}

export const chooseProductCategory = (id) => dispatch => {
    dispatch({
        type: SET_PRODUCTS_CATEGORY,
        id
    })
    dispatch(getProducts())
}

export const resetProductsCategory = () => dispatch => {
    dispatch({
        type: RESET_PRODUCTS_CATEGORY
    })
    dispatch(getProducts())
}

