import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import _ from 'lodash'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './cart.css';

import CartItems from '../components/CartItems.component';
import CartAddress from '../components/CartAddress.component';
import TextInput from '../../application/components/formComponents/TextInput';

import { addProductToCart, removeProductFromCart, updateOrderInfo } from '../actions/cart.actions';

const CartContainer = ({ cart, addProductToCart, userAddresses, userName, removeProductFromCart, updateOrderInfo, history, showPrices, logisticsMinimum }) => {

    const [cartComment, changeCartComment] = useState('');
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
    const [isNewAddress, setIsNewAddress] = useState(false);
    let productsAmountSum = cart.reduce((prev, curr) => prev + curr.amount, 0);
    let productsPriceSum = cart.reduce((prev, curr) => prev + (curr.price * curr.amount), 0);
    let logisticsMinimumValue = parseInt(logisticsMinimum);
    if (isNaN(logisticsMinimumValue) || logisticsMinimumValue < 1) {
        logisticsMinimumValue = 1;
    }
    const orderDisabled = productsPriceSum < logisticsMinimumValue;
    useEffect(() => {
        let selectedIndex = localStorage.getItem('selectedAddressIndex')
        if (!selectedIndex) localStorage.setItem('selectedAddressIndex', 0);
    }, [])

    const { t } = useTranslation();

    const handleSendOrder = (form) => {
        updateOrderInfo(form);
        history.push('/cart-summary')
    }

    const handleSelectAddress = (i) => {
        setSelectedAddressIndex(i);
        localStorage.setItem('selectedAddressIndex', i);
    }

    return (
        <section id="cart" className="cart content">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    street: '',
                    streetNumber: '',
                    postalCode: '',
                    city: '',
                }}
                validationSchema={
                    (isNewAddress) ?
                        Yup.object({
                            street: Yup.string()
                                .required(`${t('streetIsRequired')}`),
                            streetNumber: Yup.string()
                                .required(`${t('streetNumberIsRequired')}`),
                            postCode: Yup.string()
                                .required(`${t('postalCodeIsRequired')}`),
                            locality: Yup.string()
                                .required(`${t('localityIsRequired')}`),
                        })
                        : null
                }
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                    let form = {};
                    if (isNewAddress) {
                        form = {
                            description: cartComment,
                            address: values,
                            products: cart
                        }
                    } else {
                        form = {
                            description: cartComment,
                            address: userAddresses[selectedAddressIndex],
                            products: cart
                        }

                    }
                    handleSendOrder(form);
                }}
            >
                {formik => (
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <Link to="/products" className="cart__back"><span className="mr-3">{'<'}</span>{t('returnToProducts')}</Link>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <h1>{t('basket')}</h1>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <CartItems
                                    products={cart}
                                    addProductToCart={addProductToCart}
                                    removeProductFromCart={removeProductFromCart}
                                    showPrices={showPrices}
                                />
                            </div>
                        </div>


                        <div className="cart__address">
                            <CartAddress
                                addresses={userAddresses}
                                name={userName}
                                handleSelect={(i) => handleSelectAddress(i)}
                                selectedIndex={selectedAddressIndex}
                            />

                            <div className="row mt-5">
                                <div className="col-12">
                                    <button className="btn btn--primary" type="button" data-toggle="collapse" data-target="#addAddress" aria-expanded="false" aria-controls="addAddress"
                                        onClick={() => setIsNewAddress(!isNewAddress)}
                                    >{t('addShippingAddress')}</button>
                                </div>

                                <div className="col-12">
                                    <div className="add__address collapse mt-5" id="addAddress">
                                        <div className="row">
                                            <div className="col-12 mb-4">
                                                <h2>{t('addShippingAddress')}</h2>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <TextInput
                                                        id="street"
                                                        label={t('street')}
                                                        type="text"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.street}
                                                        errors={formik.errors.street}
                                                    />
                                                    <TextInput
                                                        id="streetNumber"
                                                        label={t('streetNumber')}
                                                        type="text"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.streetNumber}
                                                        errors={formik.errors.streetNumber}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <TextInput
                                                        id="postCode"
                                                        label={t('postalCode')}
                                                        type="string"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.postCode}
                                                        errors={formik.errors.postCode}
                                                    />
                                                    <TextInput
                                                        id="locality"
                                                        label={t('locality')}
                                                        type="text"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.locality}
                                                        errors={formik.errors.locality}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cart__comment mt-5">
                            <div className="row">
                                <div className="col-12">
                                    <h2>{t('orderDescription')}</h2>
                                </div>
                                <div className="col-12 mt-4">
                                    <textarea
                                        onChange={(e) => changeCartComment(e.target.value)}
                                        value={cartComment}
                                    />
                                </div>
                                {orderDisabled && <div className="col-12 mt-4 text-center text-danger">
                                    <p>{t("minimumAmountWarning")} {_.round(logisticsMinimumValue - productsPriceSum, 2)} PLN</p>
                                </div>}
                            </div>
                        </div>

                        <div className="cart__submit mt-50">
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <button
                                        className="btn btn--primary"
                                        onClick={formik.handleSubmit}
                                        disabled={orderDisabled || productsAmountSum < 1}
                                        type="submit"
                                    >{t('orderAction')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>

        </section>
    )
}

const mapStateToProps = (store) => ({
    cart: store.cart.cart,
    logisticsMinimum: _.get(store, 'app.user.logisticsMinimum', 1),
    userAddresses: _.get(store, 'app.user.addresses'),
    userName: _.get(store, 'app.user.name'),
    history: store.app.history,
    showPrices: _.get(store, 'app.user.showPrices'),

});

const mapDispatchToProps = (dispatch) => {
    return {
        addProductToCart: (product, amount, isFromLocalStorage) => dispatch(addProductToCart(product, amount, isFromLocalStorage)),
        removeProductFromCart: (product, amount) => dispatch(removeProductFromCart(product, amount)),
        updateOrderInfo: (form) => dispatch(updateOrderInfo(form)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CartContainer);