import _ from 'lodash';
import { toast } from 'react-toastify';
import Api from '../../services/api';

const NAMESPACE = "CART";
export const ADD_PRODUCT_TO_CART = `ADD_PRODUCT_TO_CART_${NAMESPACE}`;
export const SET_CART = `SET_CART_${NAMESPACE}`;
export const CLEAR_CART = `CLEAR_CART_${NAMESPACE}`;
export const SET_CART_ADDRESS = `SET_CART_ADDRESS_${NAMESPACE}`;
export const SET_CART_DESCRIPTION = `SET_CART_DESCRIPTION_${NAMESPACE}`;

export const setCart = (cart) => dispatch => {
    dispatch({
        type: SET_CART,
        cart
    })
}

export const addProductToCart = (product, amount, isFromLocalStorage) => async (dispatch, getState) => {
    let { cart: { cart } } = getState();
    let newCart = [...cart];

    product = { ...product }
    product.amount = amount;

    let duplicateFound = false;

    newCart.forEach(prod => {
        if (prod.foreignId === product.foreignId) {
            prod.amount += product.amount;
            duplicateFound = true;
        }
    })

    if (!duplicateFound) newCart.push(product);
    newCart = await applyDiscountGroups(newCart);

    dispatch(setCart([...newCart]));
    toast.info('Produkt został dodany do koszyka');

    if (!isFromLocalStorage) addToCartInLocalStorage(product);
}

export const removeProductFromCart = (product, amount = null) => async (dispatch, getState) => {
    const { cart: { cart } } = getState();
    let newCart = [...cart];

    if (!amount) {
        newCart = _.filter(newCart, (o) => {
            return o._id !== product._id;
        })

    } else {
        newCart.forEach(p => {
            if (p._id === product._id) {
                p.amount--;

                if (p.amount === 0) {
                    newCart = _.filter(newCart, (o) => {
                        return o._id !== product._id;
                    })
                }
            }
        })
    }
    newCart = await applyDiscountGroups(newCart);
    saveCartToLocalStorage(newCart);
    dispatch(setCart(newCart));
}

export const clearCart = () => dispatch => {
    dispatch({
        type: CLEAR_CART
    })
}

const getCartFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem('cart'));
}

const saveCartToLocalStorage = (cart) => {
    localStorage.setItem('cart', JSON.stringify(cart));
}
export const clearCartInLocalStorage = () => {
    localStorage.removeItem('cart');
}

const addToCartInLocalStorage = (product) => {
    let cart = getCartFromLocalStorage();
    if (!cart) cart = [];

    let duplicateFound = false;

    cart.forEach(prod => {
        if (prod._id === product._id) {
            prod.amount += product.amount;
            duplicateFound = true;
        }
    })

    if (!duplicateFound) cart.push(product);

    saveCartToLocalStorage(cart);
}

export const checkLocalStorageCartOnAppLoad = () => async (dispatch) => {
    let products = getCartFromLocalStorage();
    if (!products) return;
    products = await applyDiscountGroups(products);
    dispatch(setCart(products));
}

export const updateOrderInfo = (form) => dispatch => {
    dispatch(setCartAddress(_.get(form, 'address')));
    dispatch(setCartDescription(_.get(form, 'description')));
}

const setCartAddress = (address) => dispatch => {
    dispatch({
        type: SET_CART_ADDRESS,
        address
    })
}
const setCartDescription = (description) => dispatch => {
    dispatch({
        type: SET_CART_DESCRIPTION,
        description
    })
}

const applyDiscountGroups = async (cart) => {
    let res = await Api.post("discountGroup/calculateCart", { products: cart, type: "cart" });
    if (res && res.success) {
        return res.documents;
    } else {
        toast.info('Wystąpił błąd');
        return cart;
    }
}